import React, { useEffect } from 'react';
import { useUrlSearchParams } from 'use-url-search-params';
import AppContentView from '@projectK/components/AppContentView';
import generateRoutes from '@projectK/helpers/RouteGenerator';
import { Layouts } from '@projectK/components/AppLayout';
import { useAuthUser } from '@projectK/hooks/AuthHooks';
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '@projectK/context/LayoutContextProvider';
import { useSidebarActionsContext } from '@projectK/context/SidebarContextProvider';
import {
  anonymousStructure,
  authorizedStructure,
  authorizedStructures,
  unAuthorizedStructure,
} from '../AppRoutes';
import { useRoutes } from 'react-router-dom';
import routesConfig from '../AppRoutes/routesConfig';
import WaiverAuthenticate from '../AppRoutes/WaiverAuthenticate';

const AppLayout = () => {
  const fullUrl = window.location.href;
  var parsedUrl = new URL(fullUrl);
  var path = parsedUrl.pathname;
  const { navStyle } = useLayoutContext();
  const { user, isAuthenticated } = useAuthUser();
  const { updateNavStyle } = useLayoutActionsContext();
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext();
  const AppLayoutStyle = Layouts[navStyle];
  const [params] = useUrlSearchParams();
  let structure = '';

  if (isAuthenticated) {
    structure = authorizedStructure();
  } else {
    structure = authorizedStructures;
  }

  const generatedRoutes = generateRoutes({
    isAuthenticated: isAuthenticated,
    userRole: user?.role,
    unAuthorizedStructure,
    authorizedStructure: structure,
    anonymousStructure,
  });
  const routes = useRoutes(generatedRoutes);
  let configrole = '';
  let actualRole = null;
  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout);
    if (params.menuStyle) updateMenuStyle(params.menuStyle);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, [
    params.layout,
    params.menuStyle,
    params.sidebarImage,
    params.path,
    updateNavStyle,
    updateMenuStyle,
    setSidebarBgImage,
  ]);
  if (user) {
    configrole = user.role;
    actualRole = user.actualRole[0];
  }
  const routeconfig = routesConfig(configrole, actualRole);
  const waiverAuthenticate = WaiverAuthenticate(user);
  return (isAuthenticated &&
    waiverAuthenticate &&
    path !== '/student/waiver/' &&
    path !== '/student/waiver') ||
    (user && user?.actualUser?.length > 0) ? (
    <AppLayoutStyle routes={routes} routesConfig={routeconfig} />
  ) : (
    <AppContentView routes={routes} />
  );
};

export default AppLayout;
