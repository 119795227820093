import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import IntlMessages from '@projectK/helpers/IntlMessages';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import 'react-quill/dist/quill.snow.css';
import { alpha, Grid, Modal } from '@mui/material';
import { Form, Formik } from 'formik';
import { useInfoViewActionsContext } from '@projectK/context/InfoViewContextProvider';
import jwtAxios from '@projectK/services/auth/JWT';
import { useDropzone } from 'react-dropzone';
import AppGrid from '@projectK/components/AppGrid';
import {
  UploadModern,
  PreviewThumb,
} from '@projectK/modules/thirdParty/reactDropzone/components';
import PropTypes from 'prop-types';

const BannerUpload = (props) => {
  const { isOpen, onClose, product_Id, settings, fetchData, selectedTheme } =
    props;
  const infoViewActionsContext = useInfoViewActionsContext();
  const editorRef = useRef(null);
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getContent(); // Get content when needed
    }
  }, []);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dropzone = useDropzone({
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ]);
    },
  });

  useEffect(() => {
    setUploadedFiles(dropzone.acceptedFiles);
  }, [dropzone.acceptedFiles]);

  const onDeleteUploadFile = (file) => {
    dropzone.acceptedFiles.splice(dropzone.acceptedFiles.indexOf(file), 1);
    setUploadedFiles([...dropzone.acceptedFiles]);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        className='p-popup'
        sx={{
          bgcolor: 'background.paper',
        }}
      >
        <Formik
          initialValues={{
            product_tagline: settings?.product_tagline || null,
            show_tagline_products_page: Boolean(
              settings?.show_tagline_products_page,
            ),
            show_in_products_page: Boolean(settings?.show_in_products_page),
            product_description: settings?.product_description || null,
            short_description: settings?.short_description || null,
            badge: settings?.badge || null,
            product_theme: settings?.product_theme || null,
            product_image: settings?.product_image || null,
            background_image: settings?.background_image || null,
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            let imageUrl = null;

            if (uploadedFiles && uploadedFiles.length > 0) {
              const file = uploadedFiles[0];

              const formData = new FormData();
              formData.append('file', file);

              try {
                const response = await jwtAxios.post(`/fileuploads`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                });
                const bgFileName = await response.data;
                imageUrl = bgFileName.file;
              } catch (error) {
                console.error(
                  'Error uploading file: ',
                  error.message ||
                    error.data.message ||
                    error.data.error ||
                    error,
                );
              }
              const updatedValues = {
                ...values,
                ...(imageUrl && { background_image: imageUrl }),
              };

              jwtAxios
                .put(`/product-display-settings/${product_Id}`, updatedValues)
                .then((response) => {
                  setSubmitting(false);
                  fetchData();
                  onClose();
                  infoViewActionsContext.showMessage(
                    'Image Updated successfully!',
                  );
                })
                .catch((error) => {
                  infoViewActionsContext.fetchError(error.message);
                  setSubmitting(false);
                });
              // });
            }
          }}
        >
          {({ touched, errors }) => (
            <Form autoComplete='off'>
              <Grid item xs={12} md={12}>
                <Typography
                  variant='h5'
                  style={{
                    marginBottom: '20px',
                    color: selectedTheme
                      ? selectedTheme.primary.main
                      : 'text.primary',
                  }}
                >
                  Upload Background Image
                </Typography>
                <UploadModern
                  uploadText='Drag n drop some files here, or click to select files'
                  setUploadedFiles={setUploadedFiles}
                  dropzone={dropzone}
                />
                <Typography sx={{ mt: '5px', mb: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Note:</span>
                  Image dimensions are {`1100 × 250`}
                </Typography>

                {touched.background_image && !uploadedFiles.length ? (
                  <div
                    className='error'
                    style={{
                      color: '#d32f2f',
                      fontSize: '13px',
                      marginLeft: '13px',
                      marginTop: '6px',
                    }}
                  >
                    {errors.background_image}
                  </div>
                ) : (
                  delete errors.background_image
                )}
                <AppGrid
                  sx={{
                    maxWidth: 500,
                  }}
                  data={uploadedFiles}
                  column={4}
                  itemPadding={5}
                  renderRow={(file, index) => (
                    <PreviewThumb
                      file={file}
                      onDeleteUploadFile={onDeleteUploadFile}
                      key={index + file.path}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  <Button
                    sx={{
                      position: 'relative',
                      minWidth: 100,
                      backgroundColor: selectedTheme
                        ? selectedTheme.primary.main
                        : '#2196f3',
                      '&:hover': {
                        backgroundColor: selectedTheme
                          ? alpha(selectedTheme.primary.main, 0.8) // Adjust the alpha value as needed
                          : alpha('#2196f3', 0.8),
                      },
                    }}
                    variant='contained'
                    type='submit'
                  >
                    <IntlMessages id='common.save' />
                  </Button>
                  <Button
                    sx={{
                      position: 'relative',
                      minWidth: 100,
                      ml: 2.5,
                      borderColor: selectedTheme
                        ? selectedTheme.primary.main
                        : '#2196f3',
                      '&:hover': {
                        borderColor: selectedTheme
                          ? alpha(selectedTheme.primary.main, 0.8)
                          : alpha('#2196f3', 0.8),
                      },
                      color: selectedTheme
                        ? selectedTheme.primary.main
                        : '#2196f3',
                    }}
                    variant='outlined'
                    onClick={onClose}
                    type='cancel'
                  >
                    <IntlMessages id='common.cancel' />
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default BannerUpload;
BannerUpload.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  product_Id: PropTypes.number,
  settings: PropTypes.object,
  fetchData: PropTypes.func,
  selectedTheme: PropTypes.object,
};
