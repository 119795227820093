import React from 'react';
import { alpha, Divider, IconButton } from '@mui/material';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import IntlMessages from '@projectK/helpers/IntlMessages';
import NotificationItem from './NotificationItem';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppScrollbar from '../AppScrollbar';
import { useAuthUser } from '@projectK/hooks/AuthHooks';
import CompanyTheme from '@projectK/constants/CompanyTheme';

const AppNotificationContent = ({
  onClose,
  sxStyle,
  notificationData,
  fetchdata,
  markAllRead,
}) => {
  const companyTheme = CompanyTheme();
  const { user } = useAuthUser();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 280,
        height: '100%',
        ...sxStyle,
      }}
    >
      <Box
        sx={{
          padding: '5px 20px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: 1,
          borderBottomColor: (theme) => theme.palette.divider,
          minHeight: { xs: 56, sm: 70 },
        }}
      >
        <Typography component='h3' variant='h3'>
          <IntlMessages id='common.notifications' />({notificationData.length})
        </Typography>
        <IconButton
          sx={{
            height: 40,
            width: 40,
            marginLeft: 'auto',
            color: 'text.secondary',
          }}
          onClick={onClose}
          size='large'
        >
          <CancelOutlinedIcon />
        </IconButton>
      </Box>
      <AppScrollbar
        sx={{
          height: { xs: 'calc(100% - 96px)', sm: 'calc(100% - 110px)' },
        }}
      >
        <List sx={{ py: 0 }}>
          {notificationData.map((item) => (
            <>
              <NotificationItem
                key={item.id}
                item={item}
                fetchdata={fetchdata}
              />
              <Divider sx={{ my: 1 }} />
            </>
          ))}
        </List>
      </AppScrollbar>
      <Button
        sx={{
          borderRadius: 0,
          width: '100%',
          textTransform: 'capitalize',
          marginTop: 'auto',
          height: 40,
          backgroundColor: companyTheme ? companyTheme.primary.main : '#2196f3',
          '&:hover': {
            backgroundColor: companyTheme
              ? alpha(companyTheme.primary.main, 0.8) // Adjust the alpha value as needed
              : alpha('#2196f3', 0.8),
          },
        }}
        variant='contained'
        onClick={() => {
          markAllRead(user.id);
        }}
      >
        <IntlMessages id='common.readAll' />
      </Button>
    </Box>
  );
};

export default AppNotificationContent;

AppNotificationContent.propTypes = {
  onClose: PropTypes.func,
  fetchdata: PropTypes.func,
  sxStyle: PropTypes.object,
};
