// ForJWT Auth
import { getUserFromJwtAuth } from '@projectK/helpers/AuthHelper';
import {
  useJWTAuth,
  useJWTAuthActions,
} from '@projectK/services/auth/JWTAuthProvider';

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
  };
};

export const useAuthMethod = () => {
  const { signInUser, signUpUser, logout, forgotPassword } =
    useJWTAuthActions();

  return {
    signInUser,
    logout,
    signUpUser,
    forgotPassword,
  };
};
