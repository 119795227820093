import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Box,
  Button,
  Link,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const PopupDialog = ({ isErrorDialogOpen, setIsErrorDialogOpen }) => {
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
  };

  return (
    <Dialog
      open={isErrorDialogOpen}
      onClose={handleCloseErrorDialog}
      maxWidth='sm'
      className='locked-accounts'
    >
      <DialogTitle></DialogTitle>
      <DialogContent sx={{ color: '#000' }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'crimson',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ErrorOutlineIcon sx={{ marginRight: 2 }} /> Swap user account has
          been locked. Please reach out to your campus admin to get it unlocked.
        </Typography>
        <br />

        {/* First Contact Group */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Typography variant='h4'>
              <Box display='flex' justifyContent='left'>
                Blaine
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant='h4'>
              <Box display='flex'>
                <Link href='mailto:north@maphockey.net'>
                  north@maphockey.net
                </Link>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant='h4' sx={{ fontWeight: 'normal' }}>
              <Box display='flex' justifyContent='flex-start'>
                <Link href='tel:612-804-6353'>#612-804-6353</Link>
              </Box>
            </Typography>
          </Grid>
        </Grid>

        {/* Second Contact Group */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Typography variant='h4'>
              <Box display='flex' justifyContent='left'>
                Hudson
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant='h4'>
              <Box display='flex'>
                <Link href='mailto:east@maphockey.net'>east@maphockey.net</Link>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant='h4' sx={{ fontWeight: 'normal' }}>
              <Box display='flex' justifyContent='flex-start'>
                <Link href='tel:715-808-9566'>#715-808-9566</Link>
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Typography variant='h4'>
              <Box display='flex' justifyContent='left'>
                Plymouth
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant='h4'>
              <Box display='flex'>
                <Link href='mailto:west@maphockey.net'>west@maphockey.net</Link>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant='h4' sx={{ fontWeight: 'normal' }}>
              <Box display='flex' justifyContent='flex-start'>
                <Link href='tel:763-577-9990'>#763-577-9990</Link>
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Typography variant='h4'>
              <Box display='flex' justifyContent='left'>
                STMA
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant='h4'>
              <Box display='flex'>
                <Link href='mailto:northwest@maphockey.net'>
                  northwest@maphockey.net
                </Link>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant='h4' sx={{ fontWeight: 'normal' }}>
              <Box display='flex' justifyContent='flex-start'>
                <Link href='tel:763-577-9990'>#763-577-9990</Link>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseErrorDialog} color='primary'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupDialog;
