import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import ProductDetail from '../student/Products/ProductDetail';
const ProductWizardPage = () => {
  const { productSlug } = useParams();

  return (
    <Grid
      sx={{
        p: 5,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
      }}
      className='item-hover'
    >
      <ProductDetail slug={productSlug} />
    </Grid>
  );
};

export default ProductWizardPage;
