const HandleMobileFormat = (event, formatMobileNumber) => {
  //This condition works to formate the existing number it works only when we pass true in formatMobileNumber
  if (formatMobileNumber) {
    if (!event) {
      return ''; // Return empty string for null values
    }
    // Remove non-numeric characters
    const numericMobile = event.replace(/\D/g, '');

    // Check if the number of digits is greater than 10
    if (numericMobile.length > 10) {
      // Take the last 10 digits
      const lastTenDigits = numericMobile.slice(-10);
      // Format as ###-###-####
      return lastTenDigits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    // Check if the number of digits is exactly 10
    else if (numericMobile.length === 10) {
      // Format as ###-###-####
      return numericMobile.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    // If the number of digits is less than 10, return as is
    else {
      return event;
    }
  } else {
    let inputValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters

    // Ensure the first group of digits is not longer than 3 digits
    if (inputValue.length > 3) {
      inputValue = inputValue.slice(0, 3) + '-' + inputValue.slice(3);
    }

    // Ensure the second group of digits is not longer than 6 digits
    if (inputValue.length > 7) {
      inputValue = inputValue.slice(0, 7) + '-' + inputValue.slice(7);
    }

    // Update form field value
    event.target.value = inputValue; // Set input value to formatted value
    return inputValue || null;
  }
};
export default HandleMobileFormat;
