import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  alpha,
  Divider,
} from '@mui/material';
import CompanyTheme from '@projectK/constants/CompanyTheme';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';

const SubscriptionStartDateDialog = ({
  isOpen,
  onClose,
  onConfirm,
  startDate,
  onStartDateChange,
  selectedPurchaseOption,
  dateError,
  setStartDate,
}) => {
  const [selectedDateOption, setSelectedDateOption] = useState('');
  const companyTheme = CompanyTheme();
  const handleClose = () => {
    setSelectedDateOption('');
    setStartDate('');
    onClose();
  };

  const handleConfirm = () => {
    // If the user chose to use today's date, set startDate to today; otherwise, use the chosen start date
    const finalStartDate =
      selectedDateOption === 'today'
        ? new Date().toISOString().split('T')[0]
        : startDate;

    onConfirm(
      selectedPurchaseOption.is_deposit
        ? selectedPurchaseOption.deposit_amount
        : selectedPurchaseOption?.monthly_price === '0.00'//NOSONAR
        ? selectedPurchaseOption?.purchase_price
        : selectedPurchaseOption?.monthly_price,
      selectedPurchaseOption?.payment_plan_id,
      selectedPurchaseOption?.id,
      selectedPurchaseOption?.remaining_amount,
      finalStartDate,
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '1.1rem',
        }}
      >
        Select Your Subscription Start Date
      </DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center', marginBottom: 3 }}>
          <Typography
            variant='body1'
            sx={{ fontSize: '0.9rem', color: 'text.primary' }}
          >
            Please choose the start date for your recurring subscription. You
            can either specify a custom date or select the option to use today's
            date as the start date.
          </Typography>
        </Box>
        <Divider sx={{ my: 5 }} />
        <RadioGroup
          value={selectedDateOption}
          onChange={(e) => setSelectedDateOption(e.target.value)}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <FormControlLabel
            value='custom'
            control={<Radio />}
            label='Specify a custom start date'
            sx={{ alignItems: 'center' }}
          />
          {selectedDateOption === 'custom' && (
            <TextField
              type='date'
              value={startDate || ''}
              onChange={onStartDateChange}
              fullWidth
              margin='normal'
              label='Start Date'
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date().toISOString().split('T')[0], // Prevent selecting past dates
              }}
              error={!!dateError} // Show error if date is invalid
              helperText={dateError} // Display error message
              FormHelperTextProps={{
                style: { color: 'red' }, // Change error message color to red
              }}
              InputProps={{
                style: {
                  borderColor: dateError ? 'red' : '', // Change border color if there's an error
                },
              }}
            />
          )}
          <FormControlLabel
            value='today'
            control={<Radio />}
            label='Use today as the start date'
            sx={{ alignItems: 'center' }}
          />
        </RadioGroup>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
          <InfoIcon sx={{ color: 'info.main', mr: 2 }} />
          <Typography
            variant='body2'
            sx={{ fontSize: '0.9rem', color: 'text.secondary' }}
          >
            <b>
              Note: If you choose to use today as the start date, your
              subscription will begin immediately from the current date.
            </b>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
        <Button
          variant='outlined'
          onClick={handleClose}
          sx={{
            minWidth: 100,
            fontWeight: 'bold',
            borderColor: companyTheme ? companyTheme.primary.main : '#2196f3',
            '&:hover': {
              borderColor: companyTheme
                ? alpha(companyTheme.primary.main, 0.8)
                : alpha('#2196f3', 0.8),
            },
            color: companyTheme ? companyTheme.primary.main : '#2196f3',
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleConfirm}
          disabled={
            !selectedDateOption ||
            (selectedDateOption === 'custom' && !startDate) ||
            dateError
          }
          sx={{
            minWidth: 100,
            fontWeight: 'bold',
            backgroundColor: companyTheme
              ? companyTheme.primary.main
              : '#2196f3',
            '&:hover': {
              backgroundColor: companyTheme
                ? alpha(companyTheme.primary.main, 0.8)
                : alpha('#2196f3', 0.8),
            },
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionStartDateDialog;

SubscriptionStartDateDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  startDate: PropTypes.string,
  onStartDateChange: PropTypes.func,
  selectedPurchaseOption: PropTypes.object,
  dateError: PropTypes.func,
  setStartDate: PropTypes.func,
};
