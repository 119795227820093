import React from 'react';
import { alpha, Box } from '@mui/material';

const SimilarProduct = () => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.6),
        mt: 2,
      }}
    ></Box>
  );
};

export default SimilarProduct;
