import React from 'react';

const Signin = React.lazy(() => import('../../modules/auth/Signin'));
const Signup = React.lazy(() => import('../../modules/auth/Signup'));
const ForgotPassword = React.lazy(() =>
  import('../../modules/auth/ForgetPassword'),
);
const ResetPassword = React.lazy(() =>
  import('../../modules/auth/ResetPassword'),
);
const Invitation = React.lazy(() =>
  import('../../modules/auth/Signup/Invitation'),
);
const ProductWizardPage = React.lazy(() =>
  import('../../modules/ProductWizardPage'),
);
const PackageWidget = React.lazy(() => import('../../modules/PackageWidget'));
const ProductListing = React.lazy(() =>
  import('../../modules/student/Products/ProductListing'),
);
const TermsAndCondition = React.lazy(() =>
  import('../../modules/Pages/TermsAndCondition'),
);

const PrivacyPolicy = React.lazy(() =>
  import('../../modules/Pages/PrivacyPolicy'),
);
export const authRouteConfig = [
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: '/invitation/activate/:token',
    element: <Invitation />,
  },
  {
    path: '/product-listing',
    element: <ProductListing />,
  },
  {
    path: '/widgets/products/:productSlug',
    element: <ProductWizardPage />,
  },
  {
    path: '/widgets/packages/:packageSlug',
    element: <PackageWidget />,
  },
  {
    path: '/terms-conditions',
    element: <TermsAndCondition />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
];
