import React from 'react';
import Box from '@mui/material/Box';
import { Fonts } from '@projectK/constants/AppEnums';
import PropTypes from 'prop-types';
import productThemes from '@projectK/constants/ProductThemes';

const Header = ({ product }) => {
  const selectedTheme = productThemes.find(
    (theme) => theme.title === product?.displaySettings?.product_theme,
  );
  return (
    <Box
      sx={{
        // display: 'flex',
        // alignItems: 'center',
        // flexDirection: { xs: 'column', sm: 'row' },
        pb: 3,
        mb: 3,
        borderBottom: `1px solid ${
          selectedTheme ? selectedTheme.primary.main : 'text.primary'
        }`,
      }}
    >
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Box
          className='wordbreak'
          component='h3'
          sx={{
            color: selectedTheme ? selectedTheme.primary.main : 'text.primary',
            fontWeight: Fonts.BOLD,
            fontSize: 16,
            mb: 1,
          }}
        >
          {product.product_name} - {product?.displaySettings?.badge}
        </Box>
        {product &&
        product?.displaySettings?.show_tagline_products_page === 1 ? (
          <Box
            className='wordbreak'
            component='h5'
            sx={{
              color: selectedTheme
                ? selectedTheme.primary.main
                : 'primary.main',
              fontSize: 14,
              mb: 1,
            }}
          >
            {product.displaySettings.product_tagline}
          </Box>
        ) : (
          ''
        )}

        <Box
          className='wordbreak'
          component='h5'
          sx={{
            color: 'text.secondary',
            fontSize: 14,
            mb: 1,
          }}
        >
          {product?.displaySettings?.short_description}
        </Box>
      </Box>
    </Box>
  );
};

export default Header;

Header.propTypes = {
  product: PropTypes.object,
};
