import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({
  value,
  name,
  onChange,
  onBlur,
  editorRef,
  touched,
  errors,
}) => {
  return (
    <>
      <ReactQuill
        className='desc-box'
        // style={{ height: '200px' }}
        ref={editorRef}
        value={value}
        name={name}
        onChange={(content, delta, source, editor) => {
          onChange(content);
        }}
        onBlur={(range, source, quill) => {
          onBlur({
            target: { name },
          });
        }}
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ align: [] }],
            ['link', 'image'],
            ['clean'],
          ],
        }}
        formats={[
          'header',
          'font',
          'list',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'align',
          'link',
          'image',
        ]}
      />
      {touched && errors && (
        <div
          className='error'
          style={{
            color: '#d32f2f',
            fontSize: '13px',
            marginLeft: '13px',
            marginTop: '6px',
          }}
        >
          {errors}
        </div>
      )}
    </>
  );
};

export default RichTextEditor;
