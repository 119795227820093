import React, { useEffect, useState } from 'react';
import AppCard from '@projectK/components/AppCard';
import AppAnimate from '@projectK/components/AppAnimate';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import AppInfoView from '@projectK/components/AppInfoView';
import {
  Header,
  ProductView,
  ProductInfo,
} from '@projectK/modules/ecommerce/ProductDetail';
import AppLoader from '@projectK/components/AppLoader';
import jwtAxios from '@projectK/services/auth/JWT';
import { Button, IconButton } from '@mui/material';
import { useAuthUser } from '@projectK/hooks/AuthHooks';
import EditIcon from '@mui/icons-material/Edit';
import BannerUpload from './BannerUpload';
import productThemes from '@projectK/constants/ProductThemes';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import CompanyTheme from '@projectK/constants/CompanyTheme';

const ProductDetail = ({ slug }) => {
  const companyTheme = CompanyTheme();
  const navigate = useNavigate();
  const { productId } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthUser();
  const [purchaseDataLoading, setPurchaseDataLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      // Make a GET request using jwtAxios
      const response = await jwtAxios.get(
        `/products/${slug ? slug : productId}`,
      );
      const { data } = response;

      setList(data);
      setLoading(false);
    } catch (error) {
      console.error('An error occurred:', error);
      setLoading(false);
    }
  };

  const [purchaseOptions, setPurchaseOptions] = useState([]);
  const fetchPurchaseData = async () => {
    setPurchaseDataLoading(true);
    try {
      // Make a GET request using jwtAxios
      const response = await jwtAxios.get(
        `/product-purchase-options/${list.id}`,
        {
          params: {
            showall: false,
          },
        },
      );
      const { data } = response;
      setPurchaseOptions(data.list);
      setPurchaseDataLoading(false);
    } catch (error) {
      console.error('An error occurred:', error);
      setPurchaseDataLoading(false);
    }
  };
  useEffect(() => {
    if (list.id) {
      fetchPurchaseData();
    }
  }, [list.id]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const selectedTheme = productThemes.find(
    (theme) => theme.title === list?.displaySettings?.product_theme,
  );
  const displaySettings = list?.displaySettings;
  const backgroundImageUrl = displaySettings?.background_image
    ? `${process.env.NX_CLOUD_FRONT_URL}/${displaySettings.background_image}`
    : '/assets/images/default-backgroud.png';
  return (
    <>
      {loading ? (
        <AppLoader />
      ) : (
        <div className='p-details'>
          <IconButton
            className='icon-btn icon-btn-eye back-btn'
            sx={{
              color: companyTheme ? companyTheme.primary.main : 'text.primary',
            }}
            onClick={() => navigate('/admin/product-listing')}
          >
            <ArrowCircleLeftOutlinedIcon fontSize='medium' />
          </IconButton>

          {!slug && (
            <div style={{ position: 'relative' }}>
              <div className='p-detail-bg'>
                <img src={backgroundImageUrl} alt='background' />
              </div>
              {user?.role[0] === 'admin' && (
                <Button
                  variant='contained'
                  color='primary'
                  style={{
                    minWidth: 100,
                    maxHeight: 30,
                    position: 'absolute',
                    top: '5px',
                    left: '5px',
                    backgroundColor: selectedTheme
                      ? selectedTheme.primary.main
                      : '#2196f3',
                    paddingLeft: 0,
                  }}
                  className='edit-icon'
                  onClick={handleEditClick}
                >
                  <IconButton
                    size='small'
                    className='icon-btn icon-btn-eye'
                    sx={{ color: 'white' }}
                  >
                    <EditIcon />
                  </IconButton>
                  Update Image
                </Button>
              )}
            </div>
          )}
          <AppAnimate animation='transition.slideUpIn' delay={200}>
            <AppCard
              className='p-in-details'
              sx={{ marginBottom: '10px' }}
              onClick={() => {
                if (slug) {
                  window.open(`/signin?refurl=/products/${slug}`, '_blank');
                }
              }}
            >
              <Header product={list} />
              <ProductView
                product={list}
                purchaseOptions={purchaseOptions}
                fetchData={fetchData}
                slug={slug}
              />
            </AppCard>
            <ProductInfo
              productId={list?.id}
              product={list}
              purchaseOptions={purchaseOptions}
              loading={purchaseDataLoading}
              slug={slug}
            />
          </AppAnimate>
        </div>
      )}
      <BannerUpload
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        settings={list?.displaySettings}
        product_Id={list?.id}
        fetchData={fetchData}
        selectedTheme={selectedTheme}
      />

      <AppInfoView />
    </>
  );
};

export default ProductDetail;

ProductDetail.propTypes = {
  match: PropTypes.object,
};
