import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AppNotifications from '../../../AppNotifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AppTooltip from '../../../AppTooltip';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuthUser, useAuthMethod } from '@projectK/hooks/AuthHooks';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Badge from '@mui/material/Badge';
import jwtAxios from '@projectK/services/auth/JWT';
import { useInterval } from '@projectK/hooks/useInterval';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { AiOutlineSwap } from 'react-icons/ai';
import { useInfoViewActionsContext } from '@projectK/context/InfoViewContextProvider';
import PopupDialog from '@projectK/modules/apps/PopupDialog';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import AppGridContainer from '@projectK/components/AppGridContainer';
import CompanyTheme from '@projectK/constants/CompanyTheme';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import * as yup from 'yup';
import { HandleMobileFormat } from '@projectK/ReUseFunctions';

import dayjs from 'dayjs';

const validationSchema = yup.object({
  user_mobile: yup
    .string()
    .matches(/^\d{3}-\d{3}-\d{4}$/, 'Please enter a valid phone number.')
    .required('Please enter phone number.'),
  address: yup
    .string()
    .max(255, 'Address must be at most 255 characters.')
    .required('Please enter address.'),
  state: yup
    .string()
    .max(255, 'Address must be at most 255 characters.')
    .required('Please select state.'),
  country: yup
    .string()
    .max(255, 'Address must be at most 255 characters.')
    .required('Please select country.'),
  zip_code: yup
    .string()
    .matches(/^\d{5,6}$/, 'Zip code is not valid.')
    .required('Please enter zip code.'),
  position: yup.string().required('Please select position.'),
  // .required(<IntlMessages id='validation.genderRequired' />),
  campus_id: yup.number().required('Campus is required.'),
  emergency_contact_name: yup.string().required('Please enter contact name.'),
  relation: yup.string().required('Please enter relation.'),
  emergency_contact_number: yup
    .string()
    .matches(/^\d{3}-\d{3}-\d{4}$/, 'Please enter a valid contact number.')
    .required('Please enter contact number.'),
  grad_year: yup
    .number()
    .typeError('Graduation year must be a number.') // Ensure the year is a number
    .integer('Graduation year must be an integer.') // Ensure it's an integer
    .min(1950, 'Graduation year must be after 1900.') // Ensure it's after a specific year
    .required('Graduation year is required.'), // Make the field required
  date_of_birth: yup
    .date()
    .typeError('Please enter a valid date.')
    .required('Please enter Date of birth.'),
  city: yup.string().required('Please enter city.'),
  gender: yup.string().required('Please select gender.'),
});
const AppHeader = (props) => {//NOSONAR
  const companyTheme = CompanyTheme();
  const { logout } = useAuthMethod();
  const { user } = useAuthUser();
  const {
    isCollapsed,
    setCollapsed,
    toggleNavCollapsed,
    tooltipPosition,
    fetchDataFunction,
  } = props;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [roleAnchorEl, setRoleAnchorEl] = React.useState(null);
  const infoViewActionsContext = useInfoViewActionsContext();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (fetchDataFunction) {
      getAllCartItems();
    }
  }, [fetchDataFunction]);

  const [notificationData, setNotificationData] = useState([]);
  const notifications = async () => {
    try {
      const response = await jwtAxios.get(`/notification`);
      const { data } = response;
      setNotificationData(data.list);
    } catch (error) {
      console.log('Error fetching notifications', error);
    }
  };

  const [cartData, setCartData] = useState([]);
  const getAllCartItems = async () => {
    try {
      const response = await jwtAxios.get(`/cart`);
      const { data } = response;
      setCartData(data);
    } catch (error) {
      console.log('Error fetching cart items', error);
    }
  };
  const handleRoleOpenMenu = (event) => {
    setRoleAnchorEl(event.currentTarget);
  };

  const handleRoleCloseMenu = () => {
    setRoleAnchorEl(null);
  };

  const markAllRead = async (itemId) => {
    try {
      await jwtAxios.put(`/notification/user/${itemId}`);
      notifications();
    } catch (error) {
      infoViewActionsContext.fetchError(error.response.data.error);
    }
  };
  useInterval(() => {
    notifications();
    if (user.role[0] === 'student' || user.role[0] === 'parent') {
      getAllCartItems();
    }
  }, 30000);

  const switchActualUserBack = async () => {
    try {
      // Make the POST request using jwtAxios
      await jwtAxios.post(`/admin/actual-user`);

      window.location.reload();
    } catch (error) {
      // Handle the error here
      console.log(error);
    }
  };

  const switchBack = async (id, role, actualRole, actualUser, parentuser) => {
    try {
      // Make the POST request using jwtAxios
      await jwtAxios.post(`/admin/switch-user`, {
        selectedUserId: id,
        role,
        actualRole,
        actualUser,
        parentuser,
      });

      window.location.reload();
    } catch (error) {
      // Handle the error here
      console.log(error);
      if (error.response.data.error === 'Selected user is Inactive.') {
        setIsErrorDialogOpen(true);
      }
    }
  };

  const handleMobileFormat = (event, name) => {
    let inputValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    let formattedValue = '';

    // Ensure the first group of digits is not longer than 3 digits
    if (inputValue.length > 3) {
      inputValue = inputValue.slice(0, 3) + '-' + inputValue.slice(3);
    }

    // Ensure the second group of digits is not longer than 6 digits
    if (inputValue.length > 7) {
      inputValue = inputValue.slice(0, 7) + '-' + inputValue.slice(7);
    }

    // Ensure the total length does not exceed 12 characters
    formattedValue = inputValue;

    // Update form field value
    event.target.value = formattedValue; // Set input value to formatted value

    if (name === 'user_mobile') {
      return { name: 'user_mobile', value: formattedValue || null };
    } else if (name === 'emergency_contact_number') {
      return {
        name: 'emergency_contact_number',
        value: formattedValue || null,
      };
    }
  };

  const [campusData, setCampusData] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState({
    campus_id: '',
    campus_name: '',
  });
  useEffect(() => {
    const fetchCampus = async () => {
      try {
        const response = await jwtAxios.get(`/campus`);
        setCampusData(response.data.list);
      } catch (error) {
        console.error('Error fetching campus:', error);
      }
    };

    fetchCampus();
  }, []);

  const [userData, setUserData] = useState(null);
  const [reqFields, setReqFields] = useState([]);
  const fetchSettings = async () => {
    try {
      const response = await jwtAxios.get(`/users/${user.id}`);
      setUserData(response.data);
      const fieldsToCheck = [
        'address',
        'campus_id',
        'city',
        'state',
        'zip_code',
        'gender',
        'date_of_birth',
        'user_mobile',
        'grad_year',
        'position',
        'emergency_contact_name',
        'emergency_contact_number',
        'relation',
        'country',
      ];
      const requiredFields = fieldsToCheck.filter(
        (key) => response.data[key] === null || response.data[key] === '',
      );
      setReqFields(requiredFields);
      if (
        requiredFields?.length > 0 &&
        (user?.role[0] === 'student' || user?.actualRole[0] === 'parent') &&
        user?.actualRole[0] !== 'admin' &&
        user?.actualRole[0] !== 'instructor'
      ) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);


  const [country, setCountry] = React.useState(userData?.country || null);
  const [states, setStates] = useState([]);
  useEffect(() => {
    if (country) fetchStates();
  }, [country]);

  const fetchStates = async () => {
    try {
      const response = await jwtAxios.get(`/states`, {
        params: {
          country,
          perPage: 'all',
        },
      });
      const { data } = response;
      setStates(data.list);
    } catch (error) {
      setStates([]);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const renderUserRoles = () => {
    return user?.role?.length > 1
      ? user?.role?.map((role) => {
          return user?.role[0] !== role ? (
            <MenuItem
              sx={{ textTransform: 'capitalize' }}
              key={role}
              onClick={() => {
                // Check the condition before calling onSwitchRole

                switchBack(user.id, role, user.role[0], user.id);
              }}
            >
              <>Swap to {role}</>
            </MenuItem>
          ) : null;
        })
      : null;
  };
  const renderAdminSwapRoles = (role) => {
    if (user?.actualRole[0] === 'admin') {
      return role.role_name === 'admin' ? (
        <div className='disable-role'>{role.role_name}</div>
      ) : (
        <>Swap to {role?.role_name}</>
      );
    } else {
      return <div className='disable-role'>{role?.role_name}</div>;
    }
  };
  const renderInstructorRoles = (role) => {
    return role?.role_name === 'student' || role?.role_name === 'parent' ? (
      <>Swap to {role.role_name}</>
    ) : (
      <div className='disable-role'>Can't swap {role.role_name}</div>
    );
  };
  const renderRoleItem = (role) => {
    return user.actualRole[0] === 'instructor'
      ? // If user's role is instructor, don't show swap option for admin or instructor

        renderInstructorRoles(role)
      : // If user's role is not instructor, show all swap options
        renderAdminSwapRoles(role);
  };
  const renderSwapButton = () => {
    if (user?.role?.length > 1) {
      return (
        <Box
          sx={{
            px: 1.85,
          }}
        >
          <AppTooltip placement={tooltipPosition}>
            <IconButton
              className='icon-btn'
              sx={{
                borderRadius: '50%',
                width: 40,
                height: 40,
                color: (theme) => theme.palette.text.secondary,
                backgroundColor: (theme) => theme.palette.background.default,
                border: 1,
                borderColor: 'transparent',
                transition: 'transform 0.3s ease-in-out',
                '&:hover, &:focus': {
                  color: (theme) => theme.palette.text.primary,
                  backgroundColor: (theme) =>
                    alpha(theme.palette.background.default, 0.9),
                  borderColor: (theme) =>
                    alpha(theme.palette.text.secondary, 0.25),
                  transform: 'scale(1.1)',
                },
              }}
              onClick={handleRoleOpenMenu}
              size='large'
            >
              <PublishedWithChangesIcon />
            </IconButton>

            <Menu
              anchorEl={roleAnchorEl}
              open={Boolean(roleAnchorEl)}
              onClose={handleRoleCloseMenu}
            >
              {user?.role?.map((role) => {
                return user?.role[0] !== role ? (
                  <MenuItem
                    sx={{ textTransform: 'capitalize' }}
                    key={role}
                    onClick={() => {
                      // Check the condition before calling onSwitchRole
                      switchBack(user.id, role, user.role[0], user.id);
                    }}
                  >
                    <>Swap to {role}</>
                  </MenuItem>
                ) : null;
              })}
            </Menu>
          </AppTooltip>
        </Box>
      );
    }

    // Return a fallback Box to ensure the same return type
    return <Box sx={{ display: 'none' }} />;
  };

  return (
    <>
      <AppBar
        position='relative'
        color='inherit'
        sx={{
          boxShadow: 'none',
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          backgroundColor: 'background.paper',
          transition: 'width 0.5s ease',
          width: '100%',
        }}
        className='app-bar'
      >
        <Toolbar
          sx={{
            boxSizing: 'border-box',
            minHeight: { xs: 56, sm: 70 },
            paddingLeft: { xs: 5 },
            paddingRight: { xs: 5, md: 7.5 },
          }}
        >
          <Hidden lgDown>
            <IconButton
              sx={{ color: 'text.secondary' }}
              edge='start'
              className='menu-btn'
              color='inherit'
              aria-label='open drawer'
              onClick={() => setCollapsed(!isCollapsed)}
              size='large'
            >
              <MenuIcon
                sx={{
                  width: 35,
                  height: 35,
                }}
              />
            </IconButton>
          </Hidden>
          <Hidden lgUp>
            <IconButton
              sx={{ color: 'text.secondary' }}
              edge='start'
              className='menu-btn'
              color='inherit'
              aria-label='open drawer'
              onClick={toggleNavCollapsed}
              size='large'
            >
              <MenuIcon
                sx={{
                  width: 35,
                  height: 35,
                }}
              />
            </IconButton>
          </Hidden>
          <Box
            sx={{
              '& .logo-text': {
                display: { xs: 'none', sm: 'block' },
              },
            }}
          >
            {/* <AppLogo /> */}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}
          />
          <Box
            sx={{
              minHeight: 40,
              position: 'relative',
              '& .searchRoot': {
                position: { xs: 'absolute', sm: 'relative' },
                right: { xs: 0, sm: 'auto' },
                top: { xs: 0, sm: 'auto' },
              },
            }}
          >
            {/* <AppSearchBar iconPosition='right' placeholder='Search…' /> */}
          </Box>

          {/* <Box sx={{ ml: 4 }}>
          <AppLngSwitcher iconOnly={true} tooltipPosition='bottom' />
        </Box> */}

          <Box sx={{ ml: 4 }}>
            <Hidden smDown>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: -2,
                  marginRight: -2,
                }}
              >
                {user.actualUser ? (
                  <Box
                    sx={{
                      px: 1.85,
                    }}
                  >
                    <AppTooltip placement={tooltipPosition}>
                      <IconButton
                        className='icon-btn'
                        sx={{
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                          color: (theme) => theme.palette.text.secondary,
                          backgroundColor: (theme) =>
                            theme.palette.background.default,
                          border: 1,
                          borderColor: 'transparent',
                          transition: 'transform 0.3s ease-in-out',
                          '&:hover, &:focus': {
                            color: (theme) => theme.palette.text.primary,
                            backgroundColor: (theme) =>
                              alpha(theme.palette.background.default, 0.9),
                            borderColor: (theme) =>
                              alpha(theme.palette.text.secondary, 0.25),
                            transform: 'scale(1.1)',
                          },
                        }}
                        onClick={handleRoleOpenMenu}
                        size='large'
                      >
                        <AiOutlineSwap />
                      </IconButton>

                      <Menu
                        anchorEl={roleAnchorEl}
                        open={Boolean(roleAnchorEl)}
                        onClose={handleRoleCloseMenu}
                      >
                        {user?.selectedUserRoles?.map((role) => {
                          return user?.role[0] !== role?.role_name ? (
                            <MenuItem
                              sx={{ textTransform: 'capitalize' }}
                              key={role}
                              onClick={() => {
                                // Check the condition before calling onSwitchRole

                                switchBack(
                                  user.id,
                                  role.role_name,
                                  user.actualRole[0],
                                  user.actualUser,
                                  null,
                                );
                              }}
                            >
                              {renderRoleItem(role)}
                            </MenuItem>
                          ) : null;
                        })}

                        {user?.parentuser && (
                          <MenuItem
                            sx={{ textTransform: 'capitalize' }}
                            onClick={() =>
                              switchBack(
                                user.parentuser,
                                'parent',
                                user.actualRole[0],
                                user.actualUser,
                                null,
                              )
                            }
                          >
                            Swap Back to Parent
                          </MenuItem>
                        )}

                        <MenuItem
                          sx={{ textTransform: 'capitalize' }}
                          onClick={switchActualUserBack}
                        >
                          Swap Back
                        </MenuItem>
                      </Menu>
                    </AppTooltip>
                  </Box>
                ) : (
                  renderSwapButton()
                )}

                <Box
                  sx={{
                    px: 1.85,
                  }}
                >
                  <AppNotifications
                    notificationData={notificationData}
                    fetchData={notifications}
                    markAllRead={markAllRead}
                  />
                </Box>
                {(user.role[0] === 'student' || user.role[0] === 'parent') && (
                  <Box
                    sx={{
                      px: 1.85,
                    }}
                  >
                    <AppTooltip title='Cart' placement={tooltipPosition}>
                      <IconButton
                        className='icon-btn'
                        sx={{
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                          color: (theme) => theme.palette.text.secondary,
                          backgroundColor: (theme) =>
                            theme.palette.background.default,
                          border: 1,
                          borderColor: 'transparent',
                          transition: 'transform 0.3s ease-in-out',
                          '&:hover, &:focus': {
                            color: (theme) => theme.palette.text.primary,
                            backgroundColor: (theme) =>
                              alpha(theme.palette.background.default, 0.9),
                            borderColor: (theme) =>
                              alpha(theme.palette.text.secondary, 0.25),
                            transform: 'scale(1.1)',
                          },
                        }}
                        onClick={() => navigate('/cart')}
                        size='large'
                      >
                        <ShoppingCartOutlinedIcon />
                        <Badge
                          badgeContent={cartData?.length}
                          color='error'
                          sx={{
                            right: -3,
                            top: -16,
                            transition: 'transform 0.3s ease-in-out', // Add a transition effect for scaling
                            transform: 'scale(1)', // Initial scale value
                          }}
                        ></Badge>
                      </IconButton>
                    </AppTooltip>
                  </Box>
                )}

                <Box
                  sx={{
                    px: 1.85,
                  }}
                >
                  <AppTooltip title='Logout' placement={tooltipPosition}>
                    <IconButton
                      className='icon-btn'
                      sx={{
                        borderRadius: '50%',
                        width: 40,
                        height: 40,
                        color: (theme) => theme.palette.text.secondary,
                        backgroundColor: (theme) =>
                          theme.palette.background.default,
                        border: 1,
                        borderColor: 'transparent',
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover, &:focus': {
                          color: (theme) => theme.palette.text.primary,
                          backgroundColor: (theme) =>
                            alpha(theme.palette.background.default, 0.9),
                          borderColor: (theme) =>
                            alpha(theme.palette.text.secondary, 0.25),
                          transform: 'scale(1.1)',
                        },
                      }}
                      onClick={logout}
                      size='large'
                    >
                      <PowerSettingsNewIcon />
                    </IconButton>
                  </AppTooltip>
                </Box>
              </Box>
            </Hidden>

            <Hidden smUp>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: -2,
                  marginRight: -2,
                }}
              >
                <Box
                  sx={{
                    px: 1.85,
                  }}
                >
                  <AppTooltip title='More'>
                    <IconButton
                      sx={{
                        borderRadius: '50%',
                        width: 40,
                        height: 40,
                        color: (theme) => theme.palette.text.secondary,
                        backgroundColor: (theme) =>
                          theme.palette.background.default,
                        border: 1,
                        borderColor: 'transparent',
                        '&:hover, &:focus': {
                          color: (theme) => theme.palette.text.primary,
                          backgroundColor: (theme) =>
                            alpha(theme.palette.background.default, 0.9),
                          borderColor: (theme) =>
                            alpha(theme.palette.text.secondary, 0.25),
                        },
                      }}
                      onClick={handleClick}
                      size='large'
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </AppTooltip>
                </Box>
              </Box>
            </Hidden>
            <Menu
              id='simple-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <AppNotifications notificationData={notificationData} isMenu />
              </MenuItem>
              {(user.role[0] === 'student' || user.role[0] === 'parent') && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/cart');
                  }}
                >
                  Cart
                </MenuItem>
              )}

              {user.actualUser ? (
                <>
                  {user?.selectedUserRoles?.map((role, index) =>
                    user.role[0] !== role.role_name ? (
                      <MenuItem
                        sx={{ textTransform: 'capitalize' }}
                        key={`${role.role_name}_${index}`}
                        onClick={() =>
                          switchBack(
                            user.id,
                            role.role_name,
                            user.actualRole[0],
                            user.actualUser,
                            null,
                          )
                        }
                      >
                        {renderRoleItem(role)}
                      </MenuItem>
                    ) : null,
                  )}
                  {user?.parentuser && (
                    <MenuItem
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() =>//NOSONAR
                        switchBack(
                          user.parentuser,
                          'parent',
                          user.actualRole[0],
                          user.actualUser,
                          null,
                        )
                      }
                    >
                      Swap Back to Parent
                    </MenuItem>
                  )}
                  <MenuItem
                    sx={{ textTransform: 'capitalize' }}
                    onClick={switchActualUserBack}
                  >
                    Swap Back
                  </MenuItem>
                </>
              ) : (
                renderUserRoles()
              )}

              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {isErrorDialogOpen && (
        <PopupDialog
          setIsErrorDialogOpen={setIsErrorDialogOpen}
          isErrorDialogOpen={isErrorDialogOpen}
        />
      )}

      <Modal open={isModalOpen}>
        <Box
          className='p-popup'
          sx={{
            bgcolor: 'background.paper',
          }}
        >
          <Formik
            initialValues={{
              address: userData?.address || null,
              campus_id: userData?.campus_id || null,
              city: userData?.city || null,
              state: userData?.state || null,
              country: userData?.country || null,
              zip_code: userData?.zip_code || null,
              gender: userData?.gender || null,
              date_of_birth: userData?.date_of_birth
                ? new Date(
                    `${userData?.date_of_birth?.substring(0, 10)}T12:01:00`,
                  )
                : null,
              user_mobile:
                HandleMobileFormat(userData?.user_mobile, true) || null,
              grad_year: userData?.grad_year || null,
              position: userData?.position || null,
              emergency_contact_name: userData?.emergency_contact_name || null,
              emergency_contact_number:
                HandleMobileFormat(userData?.emergency_contact_number, true) ||
                null,
              relation: userData?.relation || null,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              const { zip_code, date_of_birth, ...rest } = values;
              const reqData = {
                zip_code: values?.zip_code.toString(),
                date_of_birth: values.date_of_birth
                  ? dayjs(values.date_of_birth).format('YYYY-MM-DD')
                  : null,
                ...rest,
              };
              try {
                await jwtAxios.put(`/users/${user.id}`, reqData);
                infoViewActionsContext.showMessage(
                  'Required fields updated successfully!',
                );
                setIsModalOpen(false);
              } catch (error) {
                infoViewActionsContext.fetchError(error.response.data.error);
              }
            }}
          >
            {({ values, setFieldValue, touched, errors, setErrors }) => (
              <Form noValidate autoComplete='off'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        marginBottom: '20px',
                      }}
                    >
                      It looks like these fields still needs to be filled out.
                      Please complete it. If you have any questions or need
                      assistance, Please contact admin. Thank you!.
                    </Typography>
                  </Box>
                  {/* <Box>
                  <IconButton
                    size='small'
                    sx={{ fontWeight: 'bold', color: '#000000' }}
                    className='icon-btn'
                    onClick={onClose}
                  >
                    <CloseRounded />
                  </IconButton>
                </Box> */}
                </Box>
                <AppGridContainer>
                  {reqFields?.includes('address') && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        name='address'
                        variant='outlined'
                        value={values.address}
                        onChange={(event) => {
                          setFieldValue(
                            'address',
                            event.target.value === ''
                              ? null
                              : event.target.value,
                          );
                          touched.address = true;
                        }}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                        sx={{
                          width: '100%',
                        }}
                        label='Address'
                      />
                    </Grid>
                  )}
                  {reqFields?.includes('city') && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        name='city'
                        variant='outlined'
                        value={values.city}
                        onChange={(event) => {
                          setFieldValue(
                            'city',
                            event.target.value === ''
                              ? null
                              : event.target.value,
                          );
                          touched.city = true;
                        }}
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city && errors.city}
                        sx={{
                          width: '100%',
                        }}
                        label='City'
                      />
                    </Grid>
                  )}
                  {reqFields?.includes('country') && (
                    <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: '100%', borderRadius: 50 }}>
                      <TextField
                        sx={{
                          width: '100%',
                        }}
                        select
                        name='country'
                        value={country}
                        label='Country'
                        error={touched.country && Boolean(errors.country)}
                        helperText={touched.country && errors.country}
                        onChange={(event) => {
                          setCountry(event.target.value);
                          setFieldValue('country', event.target.value); // Update the form field value
                        }}
                      >
                        <MenuItem value='us'>US</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  )}
                  {reqFields?.includes('state') && (
                    <Grid item xs={12} md={6}>
                    <Autocomplete
                      id='state-autocomplete'
                      options={states}
                      // getOptionLabel={(option) => option.name}
                      getOptionLabel={(option) =>
                        `${option.name} - ${option.abbreviation}`
                      }
                      value={
                        states?.find(
                          (state) => state.abbreviation === values.state,
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue(
                          'state',
                          newValue ? newValue.abbreviation : '',
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='State'
                          variant='outlined'
                          error={touched.state && Boolean(errors.state)}
                          helperText={touched.state && errors.state}
                        />
                      )}
                    />
                  </Grid>
                  )}
                  {reqFields?.includes('user_mobile') && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        name='user_mobile'
                        value={values.user_mobile}
                        inputProps={{ maxLength: 12 }}
                        error={
                          touched.user_mobile && Boolean(errors.user_mobile)
                        }
                        helperText={touched.user_mobile && errors.user_mobile}
                        onChange={(event) => {
                          setFieldValue(
                            handleMobileFormat(event, 'user_mobile').name,
                            handleMobileFormat(event, 'user_mobile').value,
                          );
                          touched.user_mobile = true;
                        }}
                        fullWidth
                        label='Phone'
                      />
                    </Grid>
                  )}
                  {reqFields?.includes('gender') && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        sx={{
                          width: '100%',
                        }}
                        select
                        name='gender'
                        value={values.gender}
                        label='Gender'
                        error={touched.gender && Boolean(errors.gender)}
                        helperText={touched.gender && errors.gender}
                        onChange={(event) => {
                          setFieldValue('gender', event.target.value); // Update the form field value
                        }}
                      >
                        <MenuItem value='female'>Female</MenuItem>
                        <MenuItem value='male'>Male</MenuItem>
                        <MenuItem value='other'>Other</MenuItem>
                      </TextField>
                    </Grid>
                  )}
                  {reqFields?.includes('date_of_birth') && (
                    <Grid item xs={12} md={6}>
                      <Grid container>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableFuture
                            openTo='year'
                            views={['year', 'month', 'day']}
                            sx={{
                              width: '100%',
                            }}
                            label='Date Of Birth'
                            value={
                              values.date_of_birth ? values.date_of_birth : null
                            }
                            onChange={(newValue) => {
                              if (newValue) {
                                setFieldValue('date_of_birth', newValue);
                                touched.date_of_birth = true;
                              } else {
                                setFieldValue('date_of_birth', null);
                              }
                            }}
                            name='date_of_birth'
                            onError={(newError) =>
                              setErrors({
                                date_of_birth: newError,
                              })
                            }
                          />
                          <FormHelperText
                            style={{
                              color: '#d32f2f',
                              marginLeft: '15px',
                            }}
                          >
                            {touched.date_of_birth && errors.date_of_birth}
                          </FormHelperText>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  )}
                  {reqFields?.includes('zip_code') && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        name='zip_code'
                        variant='outlined'
                        value={values.zip_code}
                        onChange={(event) => {
                          setFieldValue(
                            'zip_code',
                            event.target.value === ''
                              ? null
                              : event.target.value,
                          );
                          touched.zip_code = true;
                        }}
                        error={touched.zip_code && Boolean(errors.zip_code)}
                        helperText={touched.zip_code && errors.zip_code}
                        sx={{
                          width: '100%',
                        }}
                        label='Zip Code'
                      />
                    </Grid>
                  )}
                  {reqFields?.includes('campus_id') && (
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        freeSolo={false}
                        disableClearable
                        options={campusData || []}
                        onChange={(event, newValue) => {
                          setSelectedCampus(newValue);
                          setFieldValue('campus_id', newValue?.id);
                        }}
                        value={selectedCampus}
                        getOptionLabel={(option) => option.campus_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Campus'
                            name='campus_id'
                            error={
                              touched.campus_id && Boolean(errors.campus_id)
                            }
                            helperText={touched.campus_id && errors.campus_id}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {reqFields?.includes('position') && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        sx={{
                          width: '100%',
                        }}
                        select
                        name='position'
                        value={values.position}
                        label='Position'
                        onChange={(event) => {
                          setFieldValue(`position`, event.target.value); // Update the form field value
                        }}
                        error={touched.position && Boolean(errors.position)}
                        helperText={touched.position && errors.position}
                      >
                        <MenuItem key={1} value='forward'>
                          Forward
                        </MenuItem>
                        <MenuItem key={2} value='goalie'>
                          Goalie
                        </MenuItem>
                        <MenuItem key={3} value='defense'>
                          Defense
                        </MenuItem>
                      </TextField>
                    </Grid>
                  )}
                  {reqFields?.includes('grad_year') && (
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={1}>
                          <DatePicker
                            // disableFuture
                            views={['year']} // Restrict to year selection only
                            label='Graduation Year'
                            name='grad_year'
                            value={
                              values?.grad_year
                                ? new Date(values.grad_year, 0, 1)
                                : null
                            } // Use current date or pass specific value for the form field
                            onError={(newError) =>
                              setErrors(`grad_year`, newError)
                            }
                            onChange={(date) => {
                              if (date) {
                                // Set only the year value in Formik state
                                setFieldValue('grad_year', date.getFullYear());
                              } else {
                                setFieldValue('grad_year', null); // Clear if no date is selected
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={touched.grad_year && !!errors.grad_year}
                              />
                            )} // You can pass additional props here
                          />
                          {/* Displaying error message */}
                          <FormHelperText
                            style={{
                              color: '#d32f2f',
                              marginLeft: '15px',
                            }}
                          >
                            {touched.grad_year && errors.grad_year}
                          </FormHelperText>
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  )}
                  {reqFields?.includes('emergency_contact_name') && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        name='emergency_contact_name'
                        value={values.emergency_contact_name}
                        onChange={(event) => {
                          setFieldValue(
                            'emergency_contact_name',
                            event.target.value === ''
                              ? null
                              : event.target.value,
                          );
                        }}
                        variant='outlined'
                        sx={{
                          width: '100%',
                        }}
                        error={
                          touched.emergency_contact_name &&
                          Boolean(errors.emergency_contact_name)
                        }
                        helperText={
                          touched.emergency_contact_name &&
                          errors.emergency_contact_name
                        }
                        label='Emergency Contact Name'
                      />
                    </Grid>
                  )}
                  {reqFields?.includes('emergency_contact_number') && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        name='emergency_contact_number'
                        value={values.emergency_contact_number}
                        inputProps={{ maxLength: 12 }}
                        onChange={(event) => {
                          setFieldValue(
                            handleMobileFormat(
                              event,
                              'emergency_contact_number',
                            ).name,
                            handleMobileFormat(
                              event,
                              'emergency_contact_number',
                            ).value,
                          );
                          touched.emergency_contact_number = true;
                        }}
                        error={
                          touched.emergency_contact_number &&
                          Boolean(errors.emergency_contact_number)
                        }
                        helperText={
                          touched.emergency_contact_number &&
                          errors.emergency_contact_number
                        }
                        variant='outlined'
                        sx={{
                          width: '100%',
                        }}
                        label='Emergency Contact Number'
                      />
                    </Grid>
                  )}
                  {reqFields?.includes('relation') && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        name='relation'
                        value={values.relation}
                        onChange={(event) => {
                          setFieldValue(
                            'relation',
                            event.target.value === ''
                              ? null
                              : event.target.value,
                          );
                        }}
                        variant='outlined'
                        sx={{
                          width: '100%',
                        }}
                        error={touched.relation && Boolean(errors.relation)}
                        helperText={touched.relation && errors.relation}
                        label='Relation'
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        // alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        sx={{
                          position: 'relative',
                          minWidth: 100,
                          backgroundColor: companyTheme
                            ? companyTheme.primary.main
                            : '#2196f3',
                          '&:hover': {
                            backgroundColor: companyTheme
                              ? alpha(companyTheme.primary.main, 0.8) // Adjust the alpha value as needed
                              : alpha('#2196f3', 0.8),
                          },
                        }}
                        variant='contained'
                        type='submit'
                      >
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </AppGridContainer>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};
export default AppHeader;

AppHeader.defaultProps = {
  drawerPosition: 'right',
  tooltipPosition: 'bottom',
};

AppHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
  toggleNavCollapsed: PropTypes.func,
  tooltipPosition: PropTypes.string,
  fetchDataFunction: PropTypes.func,
  drawerPosition: PropTypes.string,
};
