import React, { useEffect, useState } from 'react';
import orange from '@mui/material/colors/orange';
import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Fonts } from '@projectK/constants/AppEnums';
import PropTypes from 'prop-types';
import { useAuthUser } from '@projectK/hooks/AuthHooks';
import jwtAxios from '@projectK/services/auth/JWT';
const UserInfo = ({ color }) => {
  const { user } = useAuthUser();
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const [settings, setBasicSettings] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await jwtAxios.get(`/users/${user.id}`);
        setBasicSettings(response.data);
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    fetchSettings();
  }, []);

  const getUserAvatar = () => {
    if (user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };
  const [generalSettings, setGeneralSettings] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await jwtAxios.get(`/app-settings/`);
      const { data } = response;
      setGeneralSettings(data.basic);
    } catch (error) {
      // Handle error
      console.error('Error fetching appearance settings:', error);
    }
  };
  return (
    <>
      <Box
        sx={{
          width: { xs: 'calc(100% - 62px)', xl: 'calc(100% - 72px)' },
          alignItems: 'center',
          margin: 'auto',
          borderBottom: '1px solid #aaa',
        }}
        className='home-logo-info'
      >
        <Box
          sx={{
            alignItems: 'center',
            padding: 3,
          }}
        >
          <img
            src={`${process.env.NX_CLOUD_FRONT_URL}/${generalSettings?.home_logo_url}`}
            alt='home_logo'
          />
        </Box>
      </Box>
      <Box
        sx={{
          py: 3,
          px: 3,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        className='user-info-view'
      >
        <Box sx={{ py: 0.5 }}>
          {settings?.user_profile_pic ? (
            <Avatar
              sx={{
                height: 40,
                width: 40,
                fontSize: 24,
                backgroundColor: orange[500],
              }}
              src={`${process.env.NX_CLOUD_FRONT_URL}/${settings?.user_profile_pic}`}
            />
          ) : (
            <Avatar
              sx={{
                height: 40,
                width: 40,
                fontSize: 24,
                backgroundColor: orange[500],
              }}
            >
              {getUserAvatar()}
            </Avatar>
          )}
        </Box>
        <Box
          sx={{
            width: { xs: 'calc(100% - 62px)', xl: 'calc(100% - 72px)' },
            ml: 4,
            color: color,
          }}
          className='user-info'
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                mb: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: 16,
                fontWeight: Fonts.MEDIUM,
                color: 'inherit',
              }}
              component='span'
            >
              {user.displayName ? user.displayName : 'John Alex '}
            </Box>
          </Box>
          <Box
            sx={{
              mt: -0.5,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: 'inherit',
            }}
          >
            {user.role ? capitalizeFirstLetter(user.role[0]) : 'Administrators'}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserInfo;

UserInfo.defaultProps = {
  color: 'text.secondary',
};

UserInfo.propTypes = {
  color: PropTypes.string,
};
