import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Fonts } from '@projectK/constants/AppEnums';
import productThemes from '@projectK/constants/ProductThemes';
import jwtAxios from '@projectK/services/auth/JWT';
import { useParams } from 'react-router-dom';
import AppLoader from '@projectK/components/AppLoader';

const PackageWidget = () => {
  const { packageSlug } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await jwtAxios.get(`/packages/${packageSlug}`);
      const { data } = response;
      setList(data);
      setLoading(false);
    } catch (error) {
      console.error('An error occurred:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const selectedTheme = productThemes.find(
    (theme) => theme.title === list?.theme,
  );
  const imageUrl = list?.package_image
    ? `${process.env.NX_CLOUD_FRONT_URL}/${list.package_image}`
    : '/assets/images/default-image.jpg';

  return (
    <>
      {''}
      {loading ? (
        <AppLoader />
      ) : (
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <Card
            sx={{
              p: 5,
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
            className='item-hover'
            onClick={() => {
              window.open(`/signin ?refurl=/packages/${packageSlug}`, '_blank');
            }}
          >
            <Box
              sx={{
                mx: 2,
                maxHeight: { xs: 140, sm: 200, md: 260 },
                minHeight: { xs: 140, sm: 200, md: 260 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                '& img': {
                  maxHeight: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <img
                src={imageUrl}
                alt='package_image'
                style={{ width: '350px', height: '200px' }}
              />
            </Box>

            <Box
              sx={{
                mb: 1,
                color: selectedTheme
                  ? selectedTheme.primary.main
                  : 'text.primary',
                fontWeight: Fonts.BOLD,
                fontSize: 16,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              component='h3'
            >
              {list?.package_name} - {list?.badge || ''}
            </Box>

            <Box
              component='p'
              sx={{
                mb: 3,
                color: selectedTheme
                  ? selectedTheme.primary.main
                  : 'text.primary',
                fontSize: 14,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {list?.package_tagline}
            </Box>
            <Box
              component='p'
              sx={{
                mb: 3,
                color: 'text.secondary',
                fontSize: 14,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: list?.description || 'No description found',
                }}
              />
            </Box>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default PackageWidget;
