import React from 'react';
import { RoutePermittedRole } from '@projectK/constants/AppEnums';

const UserTypeSettings = React.lazy(() =>
  import('../../modules/admin/UserSettings'),
);
const ServiceSettings = React.lazy(() =>
  import('../../modules/admin/Products/ServiceSettings'),
);
const PackagesSettings = React.lazy(() =>
  import('../../modules/admin/Packages/EditPackages'),
);
const AutoLinks = React.lazy(() =>
  import('../../modules/admin/Packages/PackageLinks'),
);
const EmailTemplates = React.lazy(() =>
  import('../../modules/admin/EmailTemplates'),
);
const PromoCodeSettings = React.lazy(() =>
  import('../../modules/admin/PromoCodes'),
);
const FeeSettings = React.lazy(() =>
  import('../../modules/admin/ConvenienceFeeSettings'),
);
const General = React.lazy(() => import('../../modules/admin/General'));
const Location = React.lazy(() => import('../../modules/admin/Location'));
const EmailConfig = React.lazy(() => import('../../modules/admin/EmailConfig'));
const EmailLog = React.lazy(() => import('../../modules/admin/EmailLog'));
const Social = React.lazy(() => import('../../modules/admin/Social'));
const SportsLevel = React.lazy(() =>
  import('../../modules/admin/LevelTypeSettings'),
);
const CalendarSettings = React.lazy(() =>
  import('../../modules/admin/CalendarTypeSettings'),
);
const VerbiageSettings = React.lazy(() =>
  import('../../modules/admin/VerbiageTypeSettings'),
);
const ProductListing = React.lazy(() =>
  import('../../modules/admin/Products/Listing'),
);
const AddProduct = React.lazy(() =>
  import('../../modules/admin/Products/AddEditProduct'),
);
const Programs = React.lazy(() => import('../../modules/admin/Programs'));
const AddPrograms = React.lazy(() =>
  import('../../modules/admin/Programs/AddProgramsWizard'),
);
const EditPrograms = React.lazy(() =>
  import('../../modules/admin/Programs/EditProgramsWizard'),
);
const EditActivity = React.lazy(() =>
  import(
    '../../modules/admin/Products/ServiceSettings/ActivityInfo/EditActivityWizard'
  ),
);
const ActivitySession = React.lazy(() =>
  import(
    '../../modules/admin/Products/ServiceSettings/ActivityInfo/ActivitySessions'
  ),
);
const Packages = React.lazy(() => import('../../modules/admin/Packages'));
const AddPackages = React.lazy(() =>
  import('../../modules/admin/Packages/AddPackagesWizard'),
);
const Apex = React.lazy(() => import('../../modules/admin/Apex'));

const CreditWallet = React.lazy(() =>
  import('../../modules/admin/CreditSystem/CreditWallets'),
);
const CreditHistory = React.lazy(() =>
  import('../../modules/admin/CreditSystem/CreditsHistory'),
);
const CardDeatils = React.lazy(() =>
  import('../../modules/admin/CreditSystem/ManageCards'),
);
const Reports = React.lazy(() =>
  import('../../modules/admin/ReportManagement'),
);
const UserReports = React.lazy(() =>
  import('../../modules/admin/ReportManagement/UserReports'),
);
const FacilityLocation = React.lazy(() =>
  import('../../modules/admin/ManageFacilities/Location'),
);
const FacilityAddress = React.lazy(() =>
  import('../../modules/admin/ManageFacilities/Address'),
);
const FacilityResource = React.lazy(() =>
  import('../../modules/admin/ManageFacilities/Resource'),
);
const AttendanceList = React.lazy(() =>
  import('../../modules/admin/AttendanceReport'),
);
const WaiverSettings = React.lazy(() =>
  import('../../modules/admin/WaiverSettings'),
);
const ManageActivities = React.lazy(() =>
  import('../../modules/admin/ManageActivities'),
);
const ActivityCalendar = React.lazy(() =>
  import('../../modules/admin/ActivityCalendar'),
);
const ProductDetail = React.lazy(() =>
  import('../../modules/student/Products/ProductDetail'),
);
const ProgramDetail = React.lazy(() =>
  import('../../modules/student/Programs/ProgramDetail'),
);
const PackageDetail = React.lazy(() =>
  import('../../modules/student/Packages/PackageDetail'),
);
const ManageSessions = React.lazy(() =>
  import('../../modules/admin/ManageSessions'),
);
const OrdersHistroy = React.lazy(() =>
  import('../../modules/admin/AllOrdersHistory'),
);
const ManageCampus = React.lazy(() =>
  import('../../modules/admin/CampusSettings'),
);
const Users = React.lazy(() => import('../../modules/admin/Users'));
const Error403 = React.lazy(() => import('../../modules/errorPages/Error403'));
const SettingsPage = React.lazy(() =>
  import('../../modules/student/SettingsPage'),
);
const AccountPage = React.lazy(() =>
  import('../../modules/student/AccountInfo'),
);
const MyProducts = React.lazy(() =>
  import('../../modules/account/MyProfile/MyProducts'),
);
const UpcommingActivities = React.lazy(() =>
  import('../../modules/account/MyProfile/UpcomingActivities'),
);
const MySchedules = React.lazy(() =>
  import('../../modules/account/MyProfile/UpcomingSchedules'),
);
const Waitlist = React.lazy(() =>
  import('../../modules/account/MyProfile/WaitList'),
);
const ActivityHistory = React.lazy(() =>
  import('../../../../../libs/modules/src/lib/account/MyProfile/ManageHistory'),
);
const ProductWizardPage = React.lazy(() =>
  import('../../modules/ProductWizardPage'),
);
const PackageWidget = React.lazy(() => import('../../modules/PackageWidget'));
const WidgetInfo = React.lazy(() =>
  import('../../modules/admin/Products/ServiceSettings/WidgetInfo'),
);
const PackageWidgetUrl = React.lazy(() =>
  import('../../modules/admin/Packages/EditPackages/PackageWidgetUrl'),
);
const AddToCart = React.lazy(() =>
  import('../../modules/admin/Packages/PackageLinks/AddToCart'),
);
const AddToCartProduct = React.lazy(() =>
  import('../../modules/admin/Products/ProductLinks/AddToCartProduct'),
);
const CustomReports = React.lazy(() =>
  import('../../modules/admin/CustomReport'),
);
const AllWaitlist = React.lazy(() =>
  import('../../modules/coach/WaitlistUsers'),
);
const AdminNote = React.lazy(() =>
  import('../../modules/admin/AdminNote'),
);
export const adminPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/product-details/:productId',
    element: <ProductDetail />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/program-details/:programId',
    element: <ProgramDetail />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/package-details/:packageId',
    element: <PackageDetail />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/emailtemplates',
    element: <EmailTemplates />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/verbiage',
    element: <VerbiageSettings />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/calendar',
    element: <CalendarSettings />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/social',
    element: <Social />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/general',
    element: <General />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/location',
    element: <Location />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/email-config',
    element: <EmailConfig />,
  },

  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/email-log',
    element: <EmailLog />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/apex',
    element: <Apex />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/sportlevels',
    element: <SportsLevel />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/conveniencefee',
    element: <FeeSettings />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/usertype',
    element: <UserTypeSettings />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/promocodes',
    element: <PromoCodeSettings />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/product-listing',
    element: <ProductListing />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/add-products',
    element: <AddProduct />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/programs',
    element: <Programs />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/add-programs',
    element: <AddPrograms />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/edit-programs/:id',
    element: <EditPrograms />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/edit-activity/:productId/:activityId',
    element: <EditActivity />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/edit-session/:productId/:sessionId',
    element: <ActivitySession />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    // path: '/admin/users',
    path: '/my-profile',
    element: <Users />,
  },

  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/service/settings/:id',
    element: <ServiceSettings />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/error-pages/error-403',
    element: <Error403 />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/packages',
    element: <Packages />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/credit-wallets',
    element: <CreditWallet />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/credit-history',
    element: <CreditHistory />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/card-details',
    element: <CardDeatils />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/reports',
    element: <Reports />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/addpackages',
    element: <AddPackages />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/apps/package/settings/:id',
    element: <PackagesSettings />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/user-reports',
    element: <UserReports />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/address',
    element: <FacilityAddress />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/location',
    element: <FacilityLocation />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/resource',
    element: <FacilityResource />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/attendance',
    element: <AttendanceList />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings/waiversettings',
    element: <WaiverSettings />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/activities',
    element: <ManageActivities />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/activities-calendar',
    element: <ActivityCalendar />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/activity-session',
    element: <ManageSessions />,
  },

  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/my-account',
    element: <AccountPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/settings',
    element: <SettingsPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/my-products',
    element: <MyProducts />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/upcoming-activities',
    element: <UpcommingActivities />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/my-schedules',
    element: <MySchedules />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/my-waitlist',
    element: <Waitlist />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/activity-history',
    element: <ActivityHistory />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/manage-orders',
    element: <OrdersHistroy />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    element: <WidgetInfo />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    element: <PackageWidgetUrl />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/widgets/products/:productSlug',
    element: <ProductWizardPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/widgets/packages/:packageSlug',
    element: <PackageWidget />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/purchasePackage/:purchaseId/:packageId',
    element: <AutoLinks />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/purchasePackage/addtocart/:purchaseId/:packageId',
    element: <AddToCart />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/product/addtocart/:purchaseId/:productId',
    element: <AddToCartProduct />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/campus-settings',
    element: <ManageCampus />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/custom-report',
    element: <CustomReports />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/waitlist',
    element: <AllWaitlist />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/notes',
    element: <AdminNote />,
  },
];
