// CompanyTheme.js
import { useMemo } from 'react';
import productThemes from './ProductThemes';
import { backgroundLight, textLight } from './defaultConfig';

const CompanyTheme = () => {
  const theme = localStorage.getItem('theme');

  const selectedTheme = useMemo(() => {
    const themeTitle = theme;
    return themeTitle && themeTitle !== 'None'
      ? productThemes.find((themeColor) => themeColor.title === themeTitle)
      : null;
  }, [theme]);

  return useMemo(() => {
    return selectedTheme
      ? {
          mode: 'companyTheme',
          primary: {
            main: selectedTheme?.primary.main,
          },
          background: backgroundLight,
          text: textLight,
        }
      : null;
  }, [selectedTheme]);
};

export default CompanyTheme;
