import { authRole } from '@projectK/constants/AppConst';

export const getUserFromAuth0 = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.first_name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.User,
    };
  return user;
};

export const getUserFromFirebase = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.first_name ? user.first_name : 'Crema User',
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : '/assets/images/avatar/A11.jpg',
      role: authRole.User,
    };
  return user;
};
export const getUserFromAWS = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.first_name
        ? user.attributes.first_name
        : 'Crema User',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.User,
    };
  return user;
};

export const getUserFromJwtAuth = (user) => {
  if (user) {
    return {
      id: user.userId,
      uid: user._id,
      first_name: user.first_name,
      last_name: user.last_name,
      mobile: user.user_mobile,
      displayName: `${user.first_name} ${user.last_name}`,
      email: user.email,
      photoURL: user.avatar,
      role: user.roles,
      actualUser: user.actualUser,
      actualRole: user.actualRoles || [],
      selectedUserRoles: user.selectedUserRoles,
      parentuser: user.parentuser,
      refId: user.refId,
    };
  }

  return user;
};
