import React from 'react';
import { RoutePermittedRole } from '@projectK/constants/AppEnums';

const Products = React.lazy(() => import('../../modules/parent/Products'));

const ProductListing = React.lazy(() =>
  import('../../modules/parent/Products/ProductListing'),
);
const ProgramDetail = React.lazy(() =>
  import('../../modules/parent/Programs/ProgramDetail'),
);
const PackageDetail = React.lazy(() =>
  import('../../modules/parent/Packages/PackageDetail'),
);
const ProductDetail = React.lazy(() =>
  import('../../modules/parent/Products/ProductDetail'),
);
const OrdersHistory = React.lazy(() =>
  import('../../modules/dashboards/ecommerce/Orders'),
);
const ThridCalendar = React.lazy(() =>
  import('../../modules/thirdParty/calendar'),
);
const Cart = React.lazy(() =>
  import('../../modules/dashboards/ecommerce/Carts'),
);
const Checkout = React.lazy(() =>
  import('../../modules/dashboards/ecommerce/Checkout'),
);
const CardDetails = React.lazy(() =>
  import('../../modules/admin/CreditSystem/ManageCards'),
);
const CreditWallet = React.lazy(() =>
  import('../../modules/admin/CreditSystem/CreditWallets'),
);
const CreditHistory = React.lazy(() =>
  import('../../modules/admin/CreditSystem/CreditsHistory'),
);
const UserReports = React.lazy(() =>
  import('../../modules/admin/ReportManagement/UserReports'),
);
const AccountPage = React.lazy(() =>
  import('../../modules/student/AccountInfo'),
);
const OrderConfirmation = React.lazy(() =>
  import('../../modules/dashboards/ecommerce/Confirmation'),
);

const SettingsPage = React.lazy(() =>
  import('../../modules/student/SettingsPage'),
);
const LockerPage = React.lazy(() =>
  import('../../../../../libs/modules/src/lib/account/MyProfile/LockerPage'),
);
const ProductWizardPage = React.lazy(() =>
  import('../../modules/ProductWizardPage'),
);
const AutoLinks = React.lazy(() =>
  import('../../modules/admin/Packages/PackageLinks'),
);
const AddToCart = React.lazy(() =>
  import('../../modules/admin/Packages/PackageLinks/AddToCart'),
);
const AddToCartProduct = React.lazy(() =>
  import('../../modules/admin/Products/ProductLinks/AddToCartProduct'),
);
const AdminNote = React.lazy(() => import('../../modules/admin/AdminNote'));

const PackageWidget = React.lazy(() => import('../../modules/PackageWidget'));
const Error403 = React.lazy(() => import('../../modules/errorPages/Error403'));
const TransactionHistory = React.lazy(() =>
  import('../../modules/parent/TransactionHistory'),
);
const TilledCheckout = React.lazy(() =>
  import('../../modules/dashboards/ecommerce/Checkout-tilled'),
);
export const parentPagesConfigs = [
  // {
  //   permittedRole: RoutePermittedRole.Parent,
  //   path: '/parent/product-listing',
  //   element: <ProductListing />,
  // },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/products',
    element: <Products />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/product-details/:productId',
    element: <ProductDetail />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/program-details/:programId',
    element: <ProgramDetail />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/package-details/:packageId',
    element: <PackageDetail />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/orders-history/',
    element: <OrdersHistory />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/parent/error-pages/error-403',
    element: <Error403 />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/basic-calendar',
    element: <ThridCalendar />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/cart',
    element: <Cart />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/checkout',
    element: <Checkout />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/card-details',
    element: <CardDetails />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/credit-wallets',
    element: <CreditWallet />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/credit-history',
    element: <CreditHistory />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/user-reports',
    element: <UserReports />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/order/confirmation/:transId',
    element: <OrderConfirmation />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/my-profile',
    element: <LockerPage />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/settings',
    element: <SettingsPage />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/widgets/products/:productSlug',
    element: <ProductWizardPage />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/widgets/packages/:packageSlug',
    element: <PackageWidget />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/my-account',
    element: <AccountPage />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/purchasePackage/:purchaseId/:packageId',
    element: <AutoLinks />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/purchasePackage/addtocart/:purchaseId/:packageId',
    element: <AddToCart />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/product/addtocart/:purchaseId/:productId',
    element: <AddToCartProduct />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/parent/transaction-history',
    element: <TransactionHistory />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/notes',
    element: <AdminNote />,
  },
  {
    permittedRole: RoutePermittedRole.Parent,
    path: '/checkout-tilled',
    element: <TilledCheckout />,
  },
];
