import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, Typography, Link } from '@mui/material';
import jwtAxios from '@projectK/services/auth/JWT';
import CompanyTheme from '@projectK/constants/CompanyTheme';

const NotificationItem = (props) => {
  const { item, fetchdata } = props;
  const companyTheme = CompanyTheme();
  const [isRead, setIsRead] = useState(item?.markas_read === 1);
  const handleMarkAsRead = async () => {
    try {
      await jwtAxios.put(`/notification/${item.id}`);
      fetchdata(); // <-- Corrected usage with parentheses
      setIsRead(true);
    } catch (error) {
      console.log('Error marking notification as read', error);
    }
  };

  return (
    <ListItem
      sx={{
        padding: '8px 20px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
      }}
      className='item-hover'
    >
      <Box
        sx={{
          fontSize: 14,
          color: (theme) =>
            isRead ? theme.palette.text.secondary : theme.palette.text.primary,
        }}
      >
        <Typography>{item?.notification_message}</Typography>
      </Box>
      {!isRead && (
        <Link
          component='button'
          variant='body2'
          onClick={handleMarkAsRead}
          sx={{
            alignSelf: 'flex-end',
            marginTop: 1,
            textDecoration: 'none',
            cursor: 'pointer',
            color: companyTheme ? companyTheme.primary.main : '#2196f3',
          }}
        >
          Mark as Read
        </Link>
      )}
    </ListItem>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  fetchdata: PropTypes.func,
};
