import React from 'react';
import { RoutePermittedRole } from '@projectK/constants/AppEnums';

const EditActivity = React.lazy(() =>
  import(
    '../../modules/admin/Products/ServiceSettings/ActivityInfo/EditActivityWizard'
  ),
);
const ActivitySession = React.lazy(() =>
  import(
    '../../modules/admin/Products/ServiceSettings/ActivityInfo/ActivitySessions'
  ),
);
const Reports = React.lazy(() =>
  import('../../modules/coach/ReportManagement'),
);
const UserReports = React.lazy(() =>
  import('../../modules/coach/ReportManagement/UserReports'),
);
const FacilityLocation = React.lazy(() =>
  import('../../modules/coach/ManageFacilities/Location'),
);
const FacilityAddress = React.lazy(() =>
  import('../../modules/coach/ManageFacilities/Address'),
);
const FacilityResource = React.lazy(() =>
  import('../../modules/coach/ManageFacilities/Resource'),
);
const AttendanceList = React.lazy(() =>
  import('../../modules/coach/AttendanceReport'),
);
const ManageActivities = React.lazy(() =>
  import('../../modules/coach/ManageActivities'),
);
const ActivityCalendar = React.lazy(() =>
  import('../../modules/coach/ActivityCalendar'),
);
const ManageSessions = React.lazy(() =>
  import('../../modules/coach/ManageSessions'),
);
const ProductDetail = React.lazy(() =>
  import('../../modules/student/Products/ProductDetail'),
);
const Cart = React.lazy(() =>
  import('../../modules/dashboards/ecommerce/Carts'),
);
const ProductWizardPage = React.lazy(() =>
  import('../../modules/ProductWizardPage'),
);
const PackageWidget = React.lazy(() => import('../../modules/PackageWidget'));

const AddToCart = React.lazy(() =>
  import('../../modules/admin/Packages/PackageLinks/AddToCart'),
);
const AddToCartProduct = React.lazy(() =>
  import('../../modules/admin/Products/ProductLinks/AddToCartProduct'),
);
const SettingsPage = React.lazy(() =>
  import('../../modules/student/SettingsPage'),
);
const AccountPage = React.lazy(() =>
  import('../../modules/student/AccountInfo'),
);
const AutoLinks = React.lazy(() =>
  import('../../modules/admin/Packages/PackageLinks'),
);
const Users = React.lazy(() => import('../../modules/admin/Users'));
const Waitlist = React.lazy(() => import('../../modules/coach/WaitlistUsers'));
const AdminNote = React.lazy(() => import('../../modules/admin/AdminNote'));

export const coachPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/edit-activity/:productId/:activityId',
    element: <EditActivity />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/edit-session/:productId/:sessionId',
    element: <ActivitySession />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/reports',
    element: <Reports />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/user-reports',
    element: <UserReports />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/address',
    element: <FacilityAddress />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/location',
    element: <FacilityLocation />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/resource',
    element: <FacilityResource />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/attendance',
    element: <AttendanceList />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/activities',
    element: <ManageActivities />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/activities-calendar',
    element: <ActivityCalendar />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    // path: '/coach/activity-session',
    path: '/my-profile',
    element: <ManageSessions />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/users',
    element: <Users />,
  },

  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/my-account',
    element: <AccountPage />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/settings',
    element: <SettingsPage />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/edit-activity/:productId/:activityId',
    element: <EditActivity />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/edit-session/:productId/:sessionId',
    element: <ActivitySession />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/product-details/:productId',
    element: <ProductDetail />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/widgets/products/:productSlug',
    element: <ProductWizardPage />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/widgets/packages/:packageSlug',
    element: <PackageWidget />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/cart',
    element: <Cart />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/purchasePackage/:purchaseId/:packageId',
    element: <AutoLinks />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/purchasePackage/addtocart/:purchaseId/:packageId',
    element: <AddToCart />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/product/addtocart/:purchaseId/:productId',
    element: <AddToCartProduct />,
  },
  {
    permittedRole: RoutePermittedRole.Coach,
    path: '/coach/waitlist',
    element: <Waitlist />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/notes',
    element: <AdminNote />,
  },
];
