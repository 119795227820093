import { useEffect, useState } from 'react';
import jwtAxios from '@projectK/services/auth/JWT';

const WaiverAuthenticate = (user) => {
  const [arr, setArr] = useState(true);
  useEffect(() => {
    const isStudentOnly =
      user?.id &&
      user.role &&
      user.role.length === 1 &&
      user.role[0] === 'student';

    if (isStudentOnly) {
      fetchReportFields();
    }
  }, [user]);

  const fetchReportFields = async () => {
    try {
      if (user?.actualUser.length === 0) {
        const response = await jwtAxios.get(`/userwaiver/user/${user.id}`);
        const { data } = response;
        setArr(data);
      }
    } catch (error) {
      setArr(true);
    }
  };

  return arr;
};

export default WaiverAuthenticate;
