import axios from '@projectK/services/axios';

const jwtAxios = axios?.create({
  // eslint-disable-next-line no-restricted-globals
  baseURL:
    location.host === 'localhost:4200' ? `http://localhost:5000/api` : '/api', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.status === 401) {
      // Check if the current page is not already the sign-in page
      if (window.location.pathname !== '/signin') {
        window.location.href = '/signin';
      }
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = token;

    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
