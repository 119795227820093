import enUS from 'date-fns/locale/en-US';
import { format } from 'date-fns';

export const formatDateTimeRange = (startDateValue, startTime) => {
  const formattedStartDate =
    startDateValue &&
    format(startDateValue, 'EEE, MMM dd, yyyy', {
      locale: enUS,
    });

  // // Format startTime as 'hh:mm a' (12-hour clock with AM/PM)
  const formattedStartTime =
    startTime &&
    format(new Date(`1970-01-01T${startTime}`), 'hh:mm a', { locale: enUS });

  return (
    <div>
      {formattedStartDate} {formattedStartTime ? ` ${formattedStartTime}` : ''}
    </div>
  );
};

export const formatDateAndStartEndTimeRange = (
  startDateValue,
  startTime,
  endTime,
) => {
  const formattedStartDate =
    startDateValue &&
    format(startDateValue, 'EEE, MMM dd, yyyy', {
      locale: enUS,
    });

  const formattedStartTime =
    startTime &&
    format(new Date(`1970-01-01T${startTime}`), 'hh:mm a', { locale: enUS });

  const formattedEndTime =
    endTime &&
    format(new Date(`1970-01-01T${endTime}`), 'hh:mm a', { locale: enUS });
  return (
    <div>
      {formattedStartDate}
      <br />
      {formattedStartTime && formattedEndTime
        ? `${formattedStartTime} - ${formattedEndTime}`
        : formattedStartTime || formattedEndTime}
    </div>
  );
};
