import { VscTools } from 'react-icons/vsc';
import { RiShieldUserLine } from 'react-icons/ri';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import WalletIcon from '@mui/icons-material/Wallet';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import EventIcon from '@mui/icons-material/Event';
import { AiOutlineCalendar } from 'react-icons/ai';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
const adminRoutes = [
  // {
  //   id: 'app',
  //   title: 'Sample',
  //   messageId: 'sidebar.admin',
  //   type: 'group',
  //   children: [
  {
    id: 'users',
    title: 'Users',
    messageId: 'sidebar.pages.userList',
    type: 'item',
    icon: <RiShieldUserLine />,
    // url: '/admin/users',
    url: '/my-profile',
  },
  {
    id: 'activities-session',
    title: 'Activities Sessions',
    messageId: 'sidebar.customerAdmin.eventSearch',
    type: 'item',
    icon: <EventIcon />,
    url: '/admin/activity-session',
  },
  {
    id: 'memberInfo',
    title: 'memberInfo',
    messageId: 'sidebar.customerAdmin.memberInfo',
    type: 'collapse',
    icon: <InfoOutlinedIcon />,
    children: [
      {
        id: 'credit system',
        title: 'Credit System',
        messageId: 'sidebar.customerAdmin.creditSystem',
        type: 'collapse',
        // icon: <WalletIcon />,
        children: [
          {
            id: 'credit summary',
            title: 'Credit Summary',
            messageId: 'sidebar.customerAdmin.creditSummary',
            type: 'item',
            url: '/admin/credit-wallets',
          },
          {
            id: 'credit history',
            title: 'Credit History',
            messageId: 'sidebar.customerAdmin.creditHistory',
            type: 'item',
            url: '/admin/credit-history',
          },
        ],
      },
      {
        id: 'manageOrders',
        title: 'Manage Orders',
        messageId: 'sidebar.customerAdmin.manageOrders',
        type: 'item',
        url: '/admin/manage-orders',
      },
      {
        id: 'attendance',
        title: 'Attendance',
        messageId: 'sidebar.customerAdmin.AttendanceReport',
        type: 'item',
        url: '/admin/attendance',
      },
    ],
  },
  {
    id: 'productSetup',
    title: 'productSetup',
    messageId: 'sidebar.customerAdmin.productSetup',
    type: 'collapse',
    icon: <InventoryIcon />,
    children: [
      {
        id: 'productListing',
        title: 'Product List',
        messageId: 'sidebar.customerAdmin.productListing',
        type: 'item',
        url: '/admin/product-listing',
      },
      {
        id: 'packages',
        title: 'Package List',
        messageId: 'sidebar.customerAdmin.packageListing',
        type: 'item',
        url: '/admin/packages',
      },
      {
        id: 'manage activity',
        title: 'Manage Activities',
        messageId: 'sidebar.customerAdmin.manageActivity',
        type: 'collapse',
        children: [
          {
            id: 'activities',
            title: 'Activities List',
            messageId: 'sidebar.customerAdmin.activityListing',
            type: 'item',
            url: '/admin/activities',
          },
          {
            id: 'activities-calendar',
            title: 'Activities Calendar',
            messageId: 'sidebar.customerAdmin.activityschedular',
            type: 'item',
            url: '/admin/activities-calendar',
          },
          {
            id: 'waitlist',
            title: 'Waitlist',
            messageId: 'sidebar.pages.waitList',
            type: 'item',
            url: '/admin/waitlist',
          },
        ],
      },
      {
        id: 'report management',
        title: 'Report management',
        messageId: 'sidebar.customerAdmin.reportManagement',
        type: 'collapse',
        children: [
          {
            id: 'reports',
            title: 'Reports',
            messageId: 'sidebar.admin.reports',
            type: 'item',
            url: '/admin/reports',
          },
          {
            id: 'user reports',
            title: 'user Reports',
            messageId: 'sidebar.customerAdmin.userReport',
            type: 'item',
            url: '/admin/user-reports',
          },
          {
            id: 'custom report',
            title: 'Custom report fileds',
            messageId: 'sidebar.customerAdmin.customReports',
            type: 'item',
            url: '/admin/custom-report',
          },
        ],
      },
      {
        id: 'manage facility',
        title: 'Manage facility',
        messageId: 'sidebar.customerAdmin.manageFacilities',
        type: 'collapse',
        children: [
          {
            id: 'location',
            title: 'Location',
            messageId: 'sidebar.admin.manageLocation',
            type: 'item',
            url: '/admin/location',
          },
          {
            id: 'resurce',
            title: 'Resource',
            messageId: 'sidebar.admin.manageResource',
            type: 'item',
            url: '/admin/resource',
          },
          {
            id: 'campus',
            title: 'Campus Settings',
            messageId: 'sidebar.admin.campusSettings',
            type: 'item',
            url: '/campus-settings',
          },
        ],
      },
      // {
      //   id: 'My Waitlist',
      //   title: 'My Waitlist',
      //   messageId: 'common.waitlist',
      //   type: 'item',
      //   url: '/admin/my-waitlist',
      // },
    ],
  },

  {
    id: 'dashboard-1',
    title: 'Settings',
    messageId: 'sidebar.admin.companySettings',
    type: 'collapse',
    icon: <VscTools />,
    children: [
      {
        id: 'general_settings',
        title: 'General Settings',
        messageId: 'sidebar.admin.generalSettings',
        type: 'item',
        url: '/admin/settings/general',
      },
      {
        id: 'location_settings',
        title: 'Location Settings',
        messageId: 'sidebar.admin.locationSettings',
        type: 'item',
        url: '/admin/settings/location',
      },

      {
        id: 'social_settings',
        title: 'Social Settings',
        messageId: 'sidebar.admin.socialSettings',
        type: 'item',
        url: '/admin/settings/social',
      },
      {
        id: 'verbiage_settings',
        title: 'Verbiage Settings',
        messageId: 'sidebar.admin.verbiageSettings',
        type: 'item',
        url: '/admin/settings/verbiage',
      },

      {
        id: 'convenience_fee_settings',
        title: 'Convenience Fee',
        messageId: 'sidebar.admin.convenienceFeeSettings',
        type: 'item',
        url: '/admin/settings/conveniencefee',
      },
      {
        id: 'usertype_settings',
        title: 'User Type',
        messageId: 'sidebar.admin.userTypesSettings',
        type: 'item',
        url: '/admin/settings/usertype',
      },
      {
        id: 'promo_codes',
        title: 'Promo Codes',
        messageId: 'sidebar.admin.promoCodeSettings',
        type: 'item',
        url: '/admin/settings/promocodes',
      },
      {
        id: 'email_template_settings',
        title: 'Email Template Settings',
        messageId: 'sidebar.admin.emailTemplateSettings',
        type: 'item',
        url: '/admin/settings/emailtemplates',
      },
      {
        id: 'email_config_settings',
        title: 'Email Config Settings',
        messageId: 'sidebar.admin.emailConfig',
        type: 'item',
        url: '/admin/settings/email-config',
      },
      {
        id: 'email_log',
        title: 'Email Log',
        messageId: 'sidebar.admin.emailLog',
        type: 'item',
        url: '/admin/settings/email-log',
      },
      {
        id: 'waiver_settings',
        title: 'Waiver Settings',
        messageId: 'sidebar.product.waiversettings',
        type: 'item',
        url: '/admin/settings/waiversettings',
      },
    ],
  },
  {
    id: 'profile',
    title: 'My Locker',
    messageId: 'student.myLocker',
    type: 'collapse',
    icon: <ManageAccountsOutlinedIcon />,
    children: [
      {
        id: 'account',
        title: 'My Account Page',
        messageId: 'student.account',
        type: 'item',
        url: '/admin/my-account',
      },
      {
        id: 'settingHistory',
        title: 'Settings History',
        messageId: 'sidebar.settings',
        type: 'item',
        url: '/admin/settings',
      },
    ],
  },
  {
    id: 'notes',
    title: 'Admin Notes',
    messageId: 'Admin Notes',
    type: 'item',
    icon: <NoteOutlinedIcon />,
    url: '/admin/notes',
  },
];

const studentRoutes = [
  // {
  //   id: 'app',
  //   title: 'Sample',
  //   messageId: 'sidebar.student',
  //   type: 'group',
  //   children: [
  {
    id: 'profile',
    title: 'My Locker',
    messageId: 'student.myLocker',
    type: 'item',
    icon: <ManageAccountsOutlinedIcon />,
    url: '/my-profile',
  },

  {
    id: 'account',
    title: 'My Account Page',
    messageId: 'student.account',
    type: 'item',
    icon: <AccountBoxOutlinedIcon />,
    url: '/student/my-account',
  },
  {
    id: 'settingHistory',
    title: 'Settings History',
    messageId: 'sidebar.settings',
    type: 'item',
    icon: <SettingsIcon />,
    url: '/student/settings',
  },
  {
    id: 'calender',
    title: 'Schedular',
    messageId: 'sidebar.apps.calender',
    type: 'item',
    icon: <AiOutlineCalendar />,
    url: '/student/basic-calendar',
  },
  {
    id: 'transactionHistory',
    title: 'Transaction History',
    messageId: 'sidebar.transactionHistory',
    type: 'item',
    icon: <WalletIcon />,
    url: '/student/transaction-history',
  },
  {
    id: 'paymentCards',
    title: 'Payment Cards',
    messageId: 'sidebar.customerAdmin.cardDeatils',
    type: 'item',
    icon: <CreditCardIcon />,
    url: '/student/card-details',
  },
  {
    id: 'playerReports',
    title: 'Player Reports',
    messageId: 'sidebar.playerReports',
    type: 'item',
    icon: <DescriptionOutlinedIcon />,
    url: '/student/player-reports',
  },
];
//   },
// ];
const parentRoutes = [
  // {
  //   id: 'app',
  //   title: 'Sample',
  //   messageId: 'sidebar.parent',
  //   type: 'group',
  //   children: [
  {
    id: 'profile',
    title: 'My Locker',
    messageId: 'student.myLocker',
    type: 'item',
    icon: <ManageAccountsOutlinedIcon />,
    url: '/my-profile',
  },
  {
    id: 'account',
    title: 'My Account Page',
    messageId: 'student.account',
    type: 'item',
    icon: <AccountBoxOutlinedIcon />,
    url: '/parent/my-account',
  },
  {
    id: 'settingHistory',
    title: 'Settings History',
    messageId: 'sidebar.settings',
    type: 'item',
    icon: <SettingsIcon />,
    url: '/parent/settings',
  },
  {
    id: 'calender',
    title: 'Schedular',
    messageId: 'sidebar.apps.calender',
    type: 'item',
    icon: <AiOutlineCalendar />,
    url: '/parent/basic-calendar',
  },
  // {
  //   id: 'productListing',
  //   title: 'Products',
  //   messageId: 'sidebar.student.productListing',
  //   type: 'item',
  //   icon: <TbFileInvoice />,
  //   url: '/parent/product-listing',
  // },
  {
    id: 'orderHistory',
    title: 'Orders',
    messageId: 'sidebar.student.orderDetails',
    type: 'item',
    icon: <ShoppingBagOutlinedIcon />,
    url: '/parent/orders-history',
  },
  {
    id: 'paymentCards',
    title: 'Payment Cards',
    messageId: 'sidebar.customerAdmin.cardDeatils',
    type: 'item',
    icon: <CreditCardIcon />,
    url: '/parent/card-details',
  },
  {
    id: 'myorders',
    title: 'My Orders',
    messageId: 'sidebar.transactionHistory',
    type: 'item',
    icon: <ManageHistoryIcon />,
    url: '/parent/transaction-history',
  },
];
//   },
// ];
const coachRoutes = [
  {
    id: 'account',
    title: 'My Account Page',
    messageId: 'student.account',
    type: 'item',
    icon: <AccountBoxOutlinedIcon />,
    url: '/coach/my-account',
  },
  {
    id: 'settingHistory',
    title: 'Settings History',
    messageId: 'sidebar.settings',
    type: 'item',
    icon: <SettingsIcon />,
    url: '/coach/settings',
  },

  {
    id: 'manage activity',
    title: 'Manage Activities',
    messageId: 'sidebar.customerAdmin.manageActivity',
    type: 'collapse',
    icon: <EventNoteIcon />,
    children: [
      {
        id: 'activities',
        title: 'Activities List',
        messageId: 'sidebar.customerAdmin.activityListing',
        type: 'item',
        url: '/coach/activities',
      },
      {
        id: 'waitlist',
        title: 'Waitlist',
        messageId: 'sidebar.pages.waitList',
        type: 'item',
        url: '/coach/waitlist',
      },
    ],
  },
  {
    id: 'users',
    title: 'Users',
    messageId: 'sidebar.pages.userList',
    type: 'item',
    icon: <RiShieldUserLine />,
    url: '/coach/users',
  },
  {
    id: 'activities-session',
    title: 'Activities Sessions',
    messageId: 'sidebar.customerAdmin.eventSearch',
    type: 'item',
    icon: <EventIcon />,
    // url: '/coach/activity-session',
    url: '/my-profile',
  },
  {
    id: 'attendance',
    title: 'Attendance',
    messageId: 'sidebar.customerAdmin.AttendanceReport',
    type: 'item',
    icon: <AssessmentOutlinedIcon />,
    url: '/coach/attendance',
  },
  {
    id: 'report management',
    title: 'Report management',
    messageId: 'sidebar.customerAdmin.reportManagement',
    type: 'collapse',
    icon: <DescriptionOutlinedIcon />,
    children: [
      {
        id: 'reports',
        title: 'Reports',
        messageId: 'sidebar.admin.reports',
        type: 'item',
        url: '/coach/reports',
      },
      {
        id: 'user reports',
        title: 'user Reports',
        messageId: 'sidebar.customerAdmin.userReport',
        type: 'item',
        url: '/coach/user-reports',
      },
    ],
  },
  {
    id: 'manage facility',
    title: 'Manage facility',
    messageId: 'sidebar.customerAdmin.manageFacilities',
    type: 'collapse',
    icon: <LocationCityOutlinedIcon />,
    children: [
      {
        id: 'location',
        title: 'Location',
        messageId: 'sidebar.admin.manageLocation',
        type: 'item',
        url: '/coach/location',
      },
      {
        id: 'resurce',
        title: 'Resource',
        messageId: 'sidebar.admin.manageResource',
        type: 'item',
        url: '/coach/resource',
      },
    ],
  },
];
//   },
// ];
const notes = {
    id: 'notes',
    title: 'Admin Notes',
    messageId: 'Admin Notes',
    type: 'item',
    icon: <NoteOutlinedIcon />,
    url: '/notes',
  };
const routesConfig = (userRole, actualRole) => {
  let routes = [];

  switch (userRole && userRole[0]) {
    case 'admin':
      routes = adminRoutes || [];
      break;
    case 'student':
      routes = studentRoutes || [];
      break;
    case 'parent':
      routes = parentRoutes || [];
      break;
    case 'instructor':
      routes = coachRoutes || [];
      break;
    default:
      routes = [];
  }
  if (actualRole === 'admin' && !routes.some(route => route.id === 'notes')) {
    routes.push(notes);
  }

  return routes;
};

export default routesConfig;
