import { backgroundLight, textLight } from './defaultConfig';

const productThemes = [
  {
    mode: 'light',
    primary: {
      main: '#060808', // Black
    },
    secondary: {
      main: '#060808', // Dim Gray
    },
    background: backgroundLight, // White
    text: textLight, // Black
    title: 'Black',
  },
  {
    mode: 'light',
    primary: {
      main: '#02217a', // Blue  #02217A
    },
    secondary: {
      main: '#02207a',
    },
    background: backgroundLight,
    text: textLight,
    title: 'Blue',
  },
  {
    mode: 'light',
    primary: {
      main: '#262f30', // Grey  #262F30
    },
    secondary: {
      main: '#D3D3D3', // Light Grey
    },
    background: backgroundLight, // White
    text: textLight, // Black
    title: 'Dark Gray',
  },
  {
    mode: 'light',
    primary: {
      main: '#28e100', // Green #28E100
    },
    secondary: {
      main: '#29e100', // Lime
    },
    background: backgroundLight, // White
    text: textLight, // Black
    title: 'Green',
  },
  {
    mode: 'light',
    primary: {
      main: '#b5ccd9', // Light gray #B5CCD9
    },
    secondary: {
      main: '#b5ccd9', // Fire Brick
    },
    background: backgroundLight, // White
    text: textLight, // Black
    title: 'Light Gray',
  },
  {
    mode: 'light',
    primary: {
      main: '#d95200', // Orange
    },
    secondary: {
      main: '#d95200', // Orange
    },
    background: backgroundLight, // White
    text: textLight, // Black
    title: 'Orange',
  },
  {
    mode: 'light',
    primary: {
      main: '#cb0022', // Red #CB0022
    },
    secondary: {
      main: '#cb2200', // Light Salmon
    },
    background: backgroundLight, // White
    text: textLight, // Black
    title: 'Red',
  },
];

export default productThemes;
