import React from 'react';
import { Navigate } from 'react-router-dom';

import { authRouteConfig } from './AuthRoutes';
import Error403 from '../../modules/errorPages/Error403';
import { errorPagesConfigs } from './ErrorPagesRoutes';
import { adminPagesConfigs } from './Admin';
import { studentPagesConfigs } from './Student';
import { parentPagesConfigs } from './Parent';
import { coachPagesConfigs } from './Coach';
import { initialUrl } from '@projectK/constants/AppConst';
import { useAuthUser } from '../../../../../libs/hooks/src/AuthHooks';

let newInitialUrl = initialUrl;
const fullUrl = window.location.href;
const parts = fullUrl.split('/');
const slug = parts[parts.length - 1];
if (parts.includes('purchasePackage')) {
  localStorage.setItem('purchasePackage', 'purchasePackage');
  localStorage.setItem('packageId', parts[parts.length - 1]);
  localStorage.setItem('purchaseId', parts[parts.length - 2]);
}
if (parts.includes('purchasePackage') && parts.includes('addtocart')) {
  localStorage.setItem('purchasePackage', 'addtocart');
  localStorage.setItem('packageId', parts[parts.length - 1]);
  localStorage.setItem('purchaseId', parts[parts.length - 2]);
}
if (parts.includes('product') && parts.includes('addtocart')) {
  localStorage.setItem('productPackage', 'addtocart');
  localStorage.setItem('produceId', parts[parts.length - 1]);
  localStorage.setItem('productPurchaseId', parts[parts.length - 2]);
}

const authorizedStructure = () => {
  const { user } = useAuthUser();
  if (!user) {
    return {
      fallbackPath: '/signin',
      routes: [...authRouteConfig],
    };
  }
  const { role } = user;
  if (role[0] === 'admin') {
    return {
      fallbackPath: '/signin',
      unAuthorizedComponent: <Error403 />,
      routes: [...adminPagesConfigs],
    };
  } else if (role[0] === 'student') {
    return {
      fallbackPath: '/signin',
      unAuthorizedComponent: <Error403 />,
      routes: [...studentPagesConfigs],
    };
  } else if (role[0] === 'parent') {
    return {
      fallbackPath: '/signin',
      unAuthorizedComponent: <Error403 />,
      routes: [...parentPagesConfigs],
    };
  } else if (role[0] === 'instructor') {
    return {
      fallbackPath: '/signin',
      unAuthorizedComponent: <Error403 />,
      routes: [...coachPagesConfigs],
    };
  } else {
    // Handle other roles or unauthorized cases
    return {
      fallbackPath: '/signin',
      unAuthorizedComponent: <Error403 />,
      routes: [],
    };
  }
};
if (slug && parts[parts.length - 2] === 'products') {
  newInitialUrl = `/product-details/${slug}`;
} else if (slug && parts[parts.length - 2] === 'packages') {
  newInitialUrl = `/package-details/${slug}`;
} else if (parts[parts.length - 3] === 'purchasePackage') {
  newInitialUrl = `/purchasePackage/${parts[parts.length - 2]}/${
    parts[parts.length - 1]
  }`;
} else if (
  parts[parts.length - 3] === 'addtocart' &&
  parts[parts.length - 4] === 'purchasePackage'
) {
  newInitialUrl = `/purchasePackage/addtocart/${parts[parts.length - 2]}/${
    parts[parts.length - 1]
  }`;
} else if (
  parts[parts.length - 3] === 'addtocart' &&
  parts[parts.length - 4] === 'product'
) {
  newInitialUrl = `/product/addtocart/${parts[parts.length - 2]}/${
    parts[parts.length - 1]
  }`;
}
const authorizedStructures = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [],
};

const unAuthorizedStructure = {
  fallbackPath: newInitialUrl,
  routes: authRouteConfig,
};
const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '/',
      element: <Navigate to={newInitialUrl} />,
    },
    {
      path: '*',
      element: <Navigate to='/signin' />,
    },
  ]),
};

export {
  authorizedStructure,
  unAuthorizedStructure,
  anonymousStructure,
  authorizedStructures,
};
