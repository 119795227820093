import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jwtAxios, { setAuthToken } from './index';
import { useNavigate } from 'react-router-dom';
import { useInfoViewActionsContext } from '../../../../context/src/AppContextProvider/InfoViewContextProvider';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({
  children,
  fetchStart,
  fetchSuccess,
  fetchError,
}) => {
  const fullUrl = window.location.href;
  const parts = fullUrl.split(/%2F|\//);
  const slug = parts[parts.length - 1];

  const [jWTAuthData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const infoViewActionsContext = useInfoViewActionsContext();
  const navigate = useNavigate();

  useEffect(() => {
    const getAuthUser = () => {
      fetchStart();
      const token = localStorage.getItem('token');

      if (!token) {
        fetchSuccess();
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get('/auth')
        .then(({ data }) => {
          fetchSuccess();
          setJWTAuthData({
            user: data,
            isLoading: false,
            isAuthenticated: true,
          });
        })
        .catch(() => {
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          });
          fetchSuccess();
        });
    };

    getAuthUser();
  }, []);

  const signInUser = async ({ email, password, rememberMe }) => {
    fetchStart();
    try {
      const { data } = await jwtAxios.post('/auth/login', {
        email,
        password,
        rememberMe,
      });
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get('/auth');
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      fetchSuccess();
      if (
        res.data.roles.includes('student') &&
        parts[parts.length - 2] === 'products'
      ) {
        navigate(`/student/waiver/?refurl=/products/${slug}`);
      } else if (
        res.data.roles.includes('student') &&
        parts[parts.length - 2] === 'packages'
      ) {
        navigate(`/student/waiver/?refurl=/packages/${slug}`);
      } else if (res.data.roles.includes('student')) {
        navigate('/student/waiver');
      }
    } catch (error) {
      console.log(error);
      if (
        error.response.data.error === 'Contact an admin for further assistance'
      ) {
        setJWTAuthData({
          ...jWTAuthData,
          isAuthenticated: false,
          isLoading: false,
        });
        setIsErrorDialogOpen(true);
        fetchError();
      } else {
        setJWTAuthData({
          ...jWTAuthData,
          isAuthenticated: false,
          isLoading: false,
        });
        fetchError(error?.response?.data?.error || 'Something went wrong');
      }
    }
  };

  const signUpUser = async ({
    first_name,
    last_name,
    email,
    user_mobile,
    role_id,
    password,
    parent1_name,
    parent1_mobile,
    gender,
    date_of_birth,
    child,
    campus_id,
    address,
    association,
    city,
    emergency_contact_name,
    emergency_contact_number,
    grad_year,
    parent,
    position,
    relation,
    state,
    zip_code,
    waiverIds,
    isWaiverSign,
    country,
  }) => {
    fetchStart();
    try {
      await jwtAxios.post('/auth/register', {
        first_name,
        last_name,
        email,
        user_mobile,
        roles: [role_id],
        password,
        parent1_name,
        parent1_mobile,
        gender,
        date_of_birth,
        child,
        campus_id,
        address,
        association,
        city,
        emergency_contact_name,
        emergency_contact_number,
        grad_year,
        parent,
        position,
        relation,
        state,
        zip_code,
        waiverIds,
        isWaiverSign,
        country,
      });
      fetchSuccess();
      navigate('/signin');
      infoViewActionsContext.showMessage('Registered successfully!');
    } catch (error) {
      fetchError(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const logout = async () => {
    try {
      await jwtAxios.get('/auth/logout');
      localStorage.removeItem('token');
      localStorage.removeItem('set_campus_id');
      localStorage.removeItem('product_status');
      localStorage.removeItem('product_theme_filter');
      localStorage.removeItem('product_display_filter');
      localStorage.removeItem('product_badge');
      localStorage.removeItem('product_date_filter');
      localStorage.removeItem('package_access_method');
      localStorage.removeItem('Package_theme');
      setAuthToken();
      setJWTAuthData({
        user: null,
        isAuthenticated: false,
        isLoading: false,
      });
      fetchSuccess();
      navigate('/signin');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const forgotPassword = async ({ email }) => {
    fetchStart();
    try {
      await jwtAxios.post('/auth/forgot-password', {
        email,
      });
      fetchSuccess();
      infoViewActionsContext.showMessage('Sent mail successfully!');
    } catch (error) {
      fetchError(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
  };

  return (
    <>
      <JWTAuthContext.Provider
        value={{
          ...jWTAuthData,
        }}
      >
        <JWTAuthActionsContext.Provider
          value={{
            signUpUser,
            signInUser,
            logout,
            forgotPassword,
          }}
        >
          {children}
        </JWTAuthActionsContext.Provider>
      </JWTAuthContext.Provider>
      <Dialog
        open={isErrorDialogOpen}
        onClose={() => setIsErrorDialogOpen(false)}
        maxWidth='sm'
        className='locked-accounts'
      >
        <DialogTitle></DialogTitle>
        <DialogContent sx={{ color: '#000' }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: 'crimson',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ErrorOutlineIcon sx={{ marginRight: 2 }} /> Your account has been
            locked. Please reach out to your campus admin to get it unlocked.
          </Typography>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <Typography variant='h4'>
                <Box display='flex' justifyContent='left'>
                  <span>Blaine</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant='h4'>
                <Box display='flex'>
                  <span>
                    <a href='mailto:north@maphockey.net' target='_blank'>
                      north@maphockey.net
                    </a>
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant='h4' sx={{ fontWeight: 'normal' }}>
                <Box display='flex' justifyContent='flex-start'>
                  <span style={{ textTransform: 'capitalize' }}>
                    <a
                      href='tel:612-804-6353'
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      #612-804-6353
                    </a>
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <Typography variant='h4'>
                <Box display='flex' justifyContent='left'>
                  <span>Hudson</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant='h4'>
                <Box display='flex'>
                  <span>
                    <a href='mailto:east@maphockey.net' target='_blank'>
                      east@maphockey.net
                    </a>
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant='h4' sx={{ fontWeight: 'normal' }}>
                <Box display='flex' justifyContent='flex-start'>
                  <span style={{ textTransform: 'capitalize' }}>
                    <a
                      href='tel:612-804-6353'
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      #715-808-9566
                    </a>
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <Typography variant='h4'>
                <Box display='flex' justifyContent='left'>
                  <span>Plymouth</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant='h4'>
                <Box display='flex'>
                  <span>
                    <a href='mailto:west@maphockey.net' target='_blank'>
                      west@maphockey.net
                    </a>
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant='h4' sx={{ fontWeight: 'normal' }}>
                <Box display='flex' justifyContent='flex-start'>
                  <span style={{ textTransform: 'capitalize' }}>
                    <a
                      href='tel:612-804-6353'
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      #763-577-9990
                    </a>
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <Typography variant='h4'>
                <Box display='flex' justifyContent='left'>
                  <span>STMA</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant='h4'>
                <Box display='flex'>
                  <span>
                    <a href='mailto:northwest@maphockey.net' target='_blank'>
                      northwest@maphockey.net
                    </a>
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant='h4' sx={{ fontWeight: 'normal' }}>
                <Box display='flex' justifyContent='flex-start'>
                  <span style={{ textTransform: 'capitalize' }}>
                    <a
                      href='tel:612-804-6353'
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      #763-577-9990
                    </a>
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fetchStart: PropTypes.func.isRequired,
  fetchSuccess: PropTypes.func.isRequired,
  fetchError: PropTypes.func.isRequired,
};

export default JWTAuthAuthProvider;
