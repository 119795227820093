import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

if (
  process.env.NX_SENTRY_DSN !== undefined ||
  process.env.NX_SENTRY_DSN !== ''
) {
  console.log("NX_SENTRY_DSN",process.env.NX_SENTRY_DSN);
  Sentry.init({
    dsn: process.env.NX_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^\//,
      new RegExp(`^https://${window.location.hostname}/api`),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV, // Add environment configuration
    release: 'membertraxx-react-app@' + process.env.npm_package_version || '', // Add release tracking
  });
}
