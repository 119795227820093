import React, { useEffect, useState } from 'react';
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import productThemes from '@projectK/constants/ProductThemes';
import jwtAxios from '@projectK/services/auth/JWT';
import { useInfoViewActionsContext } from '@projectK/context';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthUser } from '@projectK/hooks/AuthHooks';
import EditIcon from '@mui/icons-material/Edit';
import ImageUpload from './ImageUpload';
import SubscriptionStartDateDialog from '../SubscriptionStartDateDialog';

const renderPurchaseOptions = ({
  purchaseOptions,
  isProductLimit,
  user,
  selectedTheme,
  slug,
  onAddToCard,
  setSelectedPurchaseOption,
  setIsSubDialogOpen,
}) => {
  return (
    <Grid
      item
      xm={12}
      sm={12}
      md={12}
      className='purchase-options'
      id='productpurchaseoption'
    >
      <Box
        sx={{
          display: 'flex', 
          flexDirection: 'column',
        }}
      >
        {purchaseOptions.length > 0 ? (
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant='h2'>Purchase Options</Typography>
          </Box>
        ) : (
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant='h3'>No Purchase Options Available</Typography>
          </Box>
        )}
        {!isProductLimit ? (
          <Box className='purchase-buttons'>
            {purchaseOptions.filter(
              (option) => option.membership_expiration_visible === 1,
            ).length > 0
              ? purchaseOptions
                  .filter(
                    (option) => option.membership_expiration_visible === 1,
                  )
                  ?.map((data, index) => {
                    return (
                      <Grid
                        key={data.id}
                        sx={{
                          display: 'block',
                          marginBottom: 5,
                          textAlign: 'center',
                        }}
                      >
                        {user?.role[0] === 'admin' ? (
                          <div>
                            <Typography
                              variant='h3'
                              sx={{ textAlign: 'center', marginBottom: 2 }}
                            >
                              <div>
                                Credits :{' '}
                                {data?.credit_option === 0 ? (
                                  <p>0.00</p>
                                ) : data.check_here_for_unlimited_credits ? (//NOSONAR
                                  `∞`
                                ) : (
                                  data?.credit_amount
                                )}
                              </div>
                            </Typography>
                            <Button
                              variant='contained'
                              color='primary'
                              style={{
                                maxWidth: 200,
                                minWidth: 140,
                                backgroundColor: selectedTheme
                                  ? selectedTheme.primary.main
                                  : '#2196f3',
                                margin: 'auto',
                              }}
                            >
                              {(() => {
                                return planType(data);
                              })()}
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Typography
                              variant='h3'
                              sx={{ textAlign: 'center', marginBottom: 2 }}
                            >
                              <div>
                                Credits :{' '}
                                {data?.credit_option === 0 ? (
                                  <p>0.00</p>
                                ) : data.check_here_for_unlimited_credits ? (//NOSONAR
                                  `∞`
                                ) : (
                                  data?.credit_amount
                                )}
                              </div>
                            </Typography>
                            <Button
                              variant='contained'
                              color='primary'
                              style={{
                                maxWidth: 200,
                                minWidth: 140,
                                backgroundColor: selectedTheme
                                  ? selectedTheme.primary.main
                                  : '#2196f3',
                              }}
                              onClick={() => {
                                if (slug) {
                                  window.open(
                                    `/signin?refurl=/products/${slug}`,
                                    '_blank',
                                  );
                                } else if (data?.payment_plan_id && data?.pay_at_once) {
                                  setSelectedPurchaseOption(data);
                                  setIsSubDialogOpen(true);
                                } else {
                                  onAddToCard(
                                    data.is_deposit
                                      ? data.deposit_amount
                                      : data?.monthly_price === '0.00'//NOSONAR
                                      ? data?.purchase_price
                                      : data?.monthly_price,
                                    data?.payment_plan_id,
                                    data?.id,
                                    data?.remaining_amount,
                                  );
                                }
                              }}
                            >
                              {(() => {
                                return planType(data);
                              })()}
                            </Button>
                          </div>
                        )}
                      </Grid>
                    );
                  })
              : 'No Purchase Options Available'}
          </Box>
        ) : (
          'Out of stock'
        )}
      </Box>
    </Grid>
  );
};
const planType = (data) => {
  let label = <span>{`$ ${data.purchase_price}`}</span>;
  if (data.pay_at_once) {
    label = `pay for ${data.plan_type} months  $${data.monthly_price}`;
  } else if (data.is_deposit) {
    label = (
      <div>
        Deposit ${data.deposit_amount}
        <br />
        {data.is_deposit && data?.payment_plan_id
          ? data.payment_plan_id === 1//NOSONAR
            ? `Monthly Payment: $${data.remaining_amount}`
            : `Payments until ${data.payment_plan_id} Months: $${data.remaining_amount}`
          : `Total: $${data.purchase_price}`}
      </div>
    );
  } else if (data.payment_plan_label) {
    label = `${data.payment_plan_label}  $${data.monthly_price}`;
  }
  return label;
};

const isFreeProduct = (purchaseOptions) => {
  const price = purchaseOptions?.[0];
  return (
    price &&
    (price.purchase_price === '0.00' || price.purchase_price === 0) &&
    (price.monthly_price === '0.00' || price.monthly_price === 0)
  );
};

const getParentText = ({ purchaseOptions }) => {
  return isFreeProduct(purchaseOptions)
    ? 'The price for this product is $0.00. Select a child to add this product to their profile?'
    : 'The price for this product is $0.00. Select a child to add this product to your cart?';
};

const getStudentText = ({ purchaseOptions }) => {
  return isFreeProduct(purchaseOptions)
    ? 'The price for this product is $0.00. Are you sure you want to add it to your profile?'
    : 'Would you like to add this product to your cart?';
};

const renderDialogForm = ({
  handleSubmit,
  membersData,
  user,
  selectedUsers,
  setSelectedUsers,
  setChildId,
  isChildIdValid,
  selectedTheme,
  handleClosePopup,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      {user?.role[0] === 'parent' && membersData?.length > 0 && (
        <Grid item xs={12} md={12}>
          <Autocomplete
            disableClearable
            options={(membersData || []).sort((a, b) =>
              (a.first_name + ' ' + a.last_name).localeCompare(
                b.first_name + ' ' + b.last_name,
                undefined,
                { numeric: true, sensitivity: 'base' },
              ),
            )}
            value={selectedUsers}
            onChange={(event, newValue) => {
              setSelectedUsers(newValue);
              setChildId(newValue?.id);
            }}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name} - ${option.email}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label='Search Member'
                name='member_id'
                error={!isChildIdValid}
                sx={{
                  mt: 4,
                }}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Box mt={2}>
          <Button
            type='submit'
            sx={{
              position: 'relative',
              minWidth: 100,
              ml: 2.5,
              backgroundColor: selectedTheme
                ? selectedTheme.primary.main
                : '#2196f3',
              '&:hover': {
                backgroundColor: selectedTheme
                  ? alpha(selectedTheme.primary.main, 0.8)
                  : alpha('#2196f3', 0.8),
              },
            }}
            disabled={!isChildIdValid && user?.role[0] === 'parent'}
            variant='contained'
          >
            Add this product
          </Button>
          <Button
            sx={{
              position: 'relative',
              minWidth: 100,
              ml: 2.5,
              borderColor: selectedTheme
                ? selectedTheme.primary.main
                : '#2196f3',
              '&:hover': {
                borderColor: selectedTheme
                  ? alpha(selectedTheme.primary.main, 0.8)
                  : alpha('#2196f3', 0.8),
              },
              color: selectedTheme ? selectedTheme.primary.main : '#2196f3',
            }}
            variant='outlined'
            onClick={handleClosePopup}
            type='button'
          >
            Cancel
          </Button>
        </Box>
      </Grid>
    </form>
  );
};
const ProductView = ({ product, purchaseOptions, fetchData, slug }) => {
  const { user } = useAuthUser();
  const { productId } = useParams();
  const selectedTheme = productThemes.find(
    (theme) => theme.title === product?.displaySettings?.product_theme,
  );
  const infoViewActionsContext = useInfoViewActionsContext();
  const navigate = useNavigate();
  const [isSubDialogOpen, setIsSubDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [freePurchaseOption, setFreePurchaseOption] = useState(null);
  const [freeProductId, setFreeProductId] = useState(null);
  const [childId, setChildId] = useState(null);
  const [isChildIdValid, setIsChildIdValid] = useState(false); // Add state for validation
  const [selectedPurchaseOption, setSelectedPurchaseOption] = useState(null);
  const handleDialogClose = () => {
    setIsSubDialogOpen(false);
  };
  const [dateError, setDateError] = useState('');
  const [membersData, setMembersData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);
  // Regex to validate date in YYYY-MM-DD format
  const validateDate = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };
  const handleStartDateChange = (event) => {
    const { value } = event.target;
    setStartDate(value);
    // Validate date format and check if it's not a past date
    if (value) {
      if (!validateDate(value)) {
        setDateError('Invalid date format.');
      } else {
        const enteredDate = new Date(value);
        const currentDate = new Date();

        // Reset the time portion of the current date for comparison
        currentDate.setHours(0, 0, 0, 0);

        if (enteredDate < currentDate) {
          setDateError('Date cannot be in the past.');
        } else {
          setDateError('');
        }
      }
    } else {
      setDateError('');
    }
  };
  useEffect(() => {
    fetchMembersData();
  }, []);
  const fetchMembersData = async () => {
    try {
      const response = await jwtAxios.get(`/user-members/${user?.id}`, {
        params: {
          perPage: 'all',
        },
      });
      const { data } = response;
      setMembersData(data.list);
    } catch (error) {
      setMembersData([]);
    }
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setChildId('');
    setSelectedUsers(null);
  };

  const onAddToCard = async (
    monthly_price,
    payment_plan_id,
    product_purchase_id,
    remaining_amount,
    startDate = null,
  ) => {//NOSONAR
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (product?.expiry_date) {
      const productExpiryDate = new Date(product?.expiry_date?.split('T')[0]);
      productExpiryDate.setHours(0, 0, 0, 0);
      if (currentDate > productExpiryDate) {
        if (isSubDialogOpen) {
          // Check if the dialog is currently open
          setIsSubDialogOpen(false); // Close the dialog
        }
        infoViewActionsContext.fetchError('Product is expired');
        return;
      }
    }
    const purchaseOption = purchaseOptions.find(
      (purchaseOption) => purchaseOption.id === product_purchase_id,
    );
    if (purchaseOption?.estimated_offline_purchase_to) {
      const purchaseOptionExpiryDate = new Date(
        purchaseOption?.estimated_offline_purchase_to.split('T')[0],
      );
      purchaseOptionExpiryDate.setHours(0, 0, 0, 0);

      if (currentDate > purchaseOptionExpiryDate) {
        infoViewActionsContext.fetchError('Purchase option is expired');
        return;
      }
    }
    if (monthly_price === '0.00' || monthly_price === 0) {
      setIsPopupOpen(true);
      setFreePurchaseOption(product_purchase_id);
      setFreeProductId(product.id);
      return;
    }
    try {
      // Make the POST request using jwtAxios
      await jwtAxios.post(`/cart/`, {
        product_id: product.id,
        quantity: 1,
        item_price: monthly_price,
        remaining_amount: remaining_amount,
        ...(payment_plan_id && { payment_plan_id }),
        ...(product_purchase_id && { product_purchase_id }),
        ...(payment_plan_id && { sub_start_date: startDate }),
        pay_at_once: Boolean(purchaseOption?.pay_at_once) || false,
        is_deposit: Boolean(purchaseOption?.is_deposit) || false,
      });
      infoViewActionsContext.showMessage(
        `${product.product_name} added to cart successfully`,
      );
      navigate('/cart');
    } catch (error) {
      infoViewActionsContext.fetchError(error?.response?.data?.error);
    }
  };

  // Validation logic for the TextField
  useEffect(() => {
    setIsChildIdValid(!!childId); // Simple validation: Check if childId is not null or empty
  }, [childId]);

  const parentText = getParentText({ purchaseOptions });
  const studentText = getStudentText({ purchaseOptions });
  const isProductLimit = product?.purchase_limit
    ? product.purchased_count >= product.purchase_limit
    : false;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (freePurchaseOption && freeProductId) {
        await jwtAxios.post(`/product-members/${freeProductId}`, [
          {
            credit_option_id: freePurchaseOption,
            send_invitation: false,
            // member_id: user.id,
            member_id:
              user?.role[0] === 'parent' && membersData?.length > 0
                ? childId
                : user?.id,
          },
        ]);
        infoViewActionsContext.showMessage(
          `${product.product_name} added to profile successfully`,
        );
        handleClosePopup();
      }
      handleClosePopup();
    } catch (error) {
      infoViewActionsContext.fetchError(error?.response?.data?.error);
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  let productName = product.product_name;

  return (
    <Grid container spacing={1}>
      <Grid item xm={12} sm={5} md={4} style={{ position: 'relative' }}>
        {product?.displaySettings?.product_image ? (
          <>
            <div className='p-img'>
              <img
                src={`${process.env.NX_CLOUD_FRONT_URL}/${product?.displaySettings?.product_image}`}
                alt='product'
              />
            </div>
            {user?.role[0] === 'admin' && (
              <Button
                variant='contained'
                color='primary'
                style={{
                  minWidth: 100,
                  maxHeight: 30,
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  backgroundColor: selectedTheme
                    ? selectedTheme.primary.main
                    : '#2196f3',
                  paddingLeft: 0,
                }}
                className='edit-icon'
                onClick={handleEditClick}
              >
                <IconButton
                  size='small'
                  className='icon-btn icon-btn-eye'
                  sx={{ color: 'white' }}
                >
                  <EditIcon />
                </IconButton>
                Update Image
              </Button>
            )}
          </>
        ) : (
          <>
            <div className='p-img'>
              <img src='/assets/images/default-backgroud.png' alt='product' />
            </div>
            {user?.role[0] === 'admin' && (
              <Button
                variant='contained'
                color='primary'
                style={{
                  minWidth: 100,
                  maxHeight: 30,
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  backgroundColor: selectedTheme
                    ? selectedTheme.primary.main
                    : '#2196f3',
                  paddingLeft: 0,
                }}
                className='edit-icon'
                onClick={handleEditClick}
              >
                <IconButton
                  size='small'
                  className='icon-btn icon-btn-eye'
                  sx={{ color: 'white' }}
                >
                  <EditIcon />
                </IconButton>
                Update Image
              </Button>
            )}
          </>
        )}
      </Grid>
      <Grid item xm={12} sm={7} md={8} className='details'>
        {user?.role[0] === 'admin' && (
          <Button
            variant='contained'
            color='primary'
            style={{
              minWidth: 100,
              alignItems: 'flex-start',
              lineHeight: '1.75',
              backgroundColor: selectedTheme
                ? selectedTheme.primary.main
                : '#2196f3',
              paddingLeft: 0,
            }}
            onClick={() => navigate(`/admin/service/settings/${productId}`)}
          >
            <IconButton
              size='small'
              className='icon-btn icon-btn-eye'
              sx={{ color: 'white' }}
            >
              <EditIcon />
            </IconButton>
            Manage&nbsp; <span className='wordbreak'>{productName}</span>
          </Button>
        )}
        <Box
          component='p'
          sx={{
            color: 'text.secondary',
            textAlign: 'justify',
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html:
                product.displaySettings?.product_description ||
                'No description found',
            }}
          />
        </Box>
      </Grid>
      {renderPurchaseOptions({
        isProductLimit,
        onAddToCard,
        purchaseOptions,
        selectedTheme,
        slug,
        user,

        setSelectedPurchaseOption,
        setIsSubDialogOpen,
      })}
      <Dialog
        className='price-alert-pop'
        open={isPopupOpen}
        onClose={handleClosePopup}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>
          <Typography variant='h4'>Price Alert</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            sx={{ fontSize: '16px', fontWeight: 'bold' }}
          >
            {user?.role[0] === 'parent' && membersData.length > 0
              ? parentText
              : studentText}
          </DialogContentText>

          {renderDialogForm({
            handleSubmit,
            membersData,
            user,
            selectedUsers,
            setSelectedUsers,
            setChildId,
            isChildIdValid,
            selectedTheme,
            handleClosePopup,
          })}
        </DialogContent>
      </Dialog>
      {isSubDialogOpen && (
        <SubscriptionStartDateDialog
          isOpen={isSubDialogOpen}
          onClose={handleDialogClose}
          onConfirm={onAddToCard}
          startDate={startDate}
          onStartDateChange={handleStartDateChange}
          selectedPurchaseOption={selectedPurchaseOption} // Pass the data here
          dateError={dateError}
          setStartDate={setStartDate}
        />
      )}
      <ImageUpload
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        settings={product.displaySettings}
        product_Id={product.id}
        fetchData={fetchData}
        selectedTheme={selectedTheme}
      />
    </Grid>
  );
};

export default ProductView;

ProductView.propTypes = {
  product: PropTypes.object,
  purchaseOptions: PropTypes.array,
  fetchData: PropTypes.func,
  slug: PropTypes.string,
};
