import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import CompanyTheme from '@projectK/constants/CompanyTheme';
import AppCard from '@projectK/components/AppCard';
import ActivitySchedule from '../ActivitySchedule';
import { useParams } from 'react-router-dom';
import productThemes from '@projectK/constants/ProductThemes';

const ProductInfo = ({
  productId,
  loading,
  product,
  purchaseOptions,
  fetchData,
}) => {
  const companyTheme = CompanyTheme();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { productSlug } = useParams();
  const selectedTheme = productThemes.find(
    (theme) => theme.title === product?.displaySettings?.product_theme,
  );
  return (
    <AppCard>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          '& .crMuiTab': {
            textTransform: 'capitalize',
            p: 0,
          },
          '& .MuiTabs-indicator': {
            backgroundColor: selectedTheme ? selectedTheme.primary.main : '',
          },
        }}
      >
        <Tab
          className='crMuiTab'
          style={{ marginRight: '25px' }}
          label='Activity Schedule'
          sx={{
            '&.Mui-selected': {
              color: selectedTheme ? selectedTheme.primary.main : '',
            },
          }}
        />
      </Tabs>
      <Box
        sx={{
          mt: 4,
        }}
      >
        {value === 0 && !loading && (
          <ActivitySchedule
            companyTheme={companyTheme}
            product_Id={productId}
            product={product}
            purchaseOptions={purchaseOptions}
            fetchData={fetchData}
            slug={productSlug}
          />
        )}
      </Box>
    </AppCard>
  );
};

export default ProductInfo;
