import React from 'react';
import { useInfoViewActionsContext } from '@projectK/context/InfoViewContextProvider';
import JWTAuthProvider from '@projectK/services/auth/JWTAuthProvider';

const AppAuthProvider = ({ children }) => {
  const { fetchStart, fetchSuccess, fetchError, showMessage } =
    useInfoViewActionsContext();

  return (
    <JWTAuthProvider
      fetchStart={fetchStart}
      fetchError={fetchError}
      fetchSuccess={fetchSuccess}
      showMessage={showMessage}
    >
      {children}
    </JWTAuthProvider>
  );
};

export default AppAuthProvider;
